"use strict";
//import {  Headers} from '@angular/common/http';
import { HttpClient, HttpHeaders } from "@angular/common/http";
export const sep = "/";
export const version: string = "22.2.2";
export const apiUrl = "http://localhost:11275/api/";

export class AppSettings {

  public static DomainUrlMotorCarInspection = 'https://motorapi.finqyinsure.com/';//prod
  public static DomainUrl = 'https://motorapi.finqyinsure.com';//prod;
  public static DomainUrlHEALTH = 'https://api.finqyinsure.com/';//prod;
  // public static API_ENDPOINTHEALTH = 'https://www.arkavach.com:8089/api';//prod
   public static API_ENDPOINTHEALTH = 'https://api.finqyinsure.com/api';//prod
  public static API_ENDPOINT = 'https://motorapi.finqyinsure.com/api';//prod
  public static API_ENDPOINT_CKYC = 'https://ckyc.finqyinsure.com/api'; // CKYC prod
  public static API_ENDPOINT2 = 'https://motorapi.finqyinsure.com';//PROD
  public static DomainUrlCare = "https://pre-pod.arkavach.com"; //uat
}

export class CommonClass {
  public static getHttpHeader(): HttpHeaders {
    const headers = new HttpHeaders({ "Content-Type": "application/json" });
    return headers;
  }
}
