import { ActivatedRoute, Router } from '@angular/router';
import { Component,ViewChild , OnInit, HostListener} from '@angular/core';
import {GlobalService} from './global.service'
import {HeaderComponent} from './common/header/header.component';
import {AuthenticationService} from 'src/app/services/authentication.service';
import { User ,UserAuthentication} from './common/Models/userDetail';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {token} from './common/Models/token';
import {ErrorHandle} from './common/models/ErrorHandle';
import Swal from 'sweetalert2';
import { EncrDecrService } from 'src/app/services/EncrDecrService';
import { HttpParams } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
// import { SessionService } from './services/session.service';

declare var $: any;
@Component({
  selector: 'app-root',

  templateUrl:  './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit  {
//   isShow: boolean;
//   topPosToStartShowing = 100;
  
//   @HostListener('window:scroll')
//   checkScroll() {
      
//     // window의 scroll top
//     // Both window.pageYOffset and document.documentElement.scrollTop returns the same result in all the cases. window.pageYOffset is not supported below IE 9.

//     const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;

//     //console.log('[scroll]', scrollPosition);
    
//     if (scrollPosition >= this.topPosToStartShowing) {
//       this.isShow = true;
//     } else {
//       this.isShow = false;
//     }
//   }

//   // TODO: Cross browsing
//   gotoTop() {
//     window.scroll({ 
//       top: 0, 
//       left: 0, 
//       behavior: 'smooth' 
//     });
//   }
  
//   title = '';
//   isLogin:boolean; 
//   @ViewChild(HeaderComponent,{static:false}) headerComponent : HeaderComponent;
//   user:any;
//   registerForm: FormGroup;
//   loginForm:FormGroup;
//     loading = false;
//     submitted = false;  
//     isLogedUser:boolean=false;

//  isRegister:boolean;
 ErrorHandle:ErrorHandle;
 token:token;
//  loginUser:User[];
//  UserAuthentication: UserAuthentication;
//  logedUser:string=null;
FullName:any;
userEmail:any;
userMobile:any;
isLogin:any;
  constructor( public globalService:GlobalService,private authService:AuthenticationService,private EncrDecr: EncrDecrService,
    private formBuilder: FormBuilder,private router:Router,private routerActive:ActivatedRoute)
    {
           
   
    //   this.globalService.showfooter=true;
    //   debugger;
 
      let vUserAuthentication = new UserAuthentication();
      vUserAuthentication.UserName='onlinehealth@gmail.com';
      vUserAuthentication.Password='123456';
      this.authService.getAuthentication(vUserAuthentication).subscribe((data: token)=>{          
        this.token=data;
        if(this.token.access_token !==''){
            // this.loading = false;
            sessionStorage.setItem('access_token',this.token.access_token);
            //this.router.navigate(["/dashboard"]);
        }
        else{
            // this.loading = false;
            Swal.fire('Some error.', '', 'error');
        }         
        
      },((err:ErrorHandle) => {
        this.ErrorHandle=err;       
         if(this.ErrorHandle.statusText=="Bad Request"){
           Swal.fire('Userid and password are incorrect.', '', 'error');
         }
    }));
    // if(sessionStorage.getItem('userName')==null){      
    //   sessionStorage.removeItem('userName');
    //   sessionStorage.removeItem('email');
    //   sessionStorage.removeItem('mobile'); 
    //   this.globalService.isLogin=false; 
    //   this.isLogin=false;
    // }else{
    //   this.globalService.isLogin=true;
    //   this.isLogin=true;
    //   this.logedUser='Welcome,'+ sessionStorage.getItem('userName');
    // } 
    // this.isLogin=true;
    // this.isRegister=false; 
    
  }

  ngOnInit() {

    if(sessionStorage.getItem('EmailId')!=null || sessionStorage.getItem('MobileNo')!=null){     
      this.FullName=sessionStorage.getItem('FullName');
      this.userEmail=sessionStorage.getItem('EmailId');
      this.userMobile=sessionStorage.getItem('MobileNo');
      this.isLogin =  sessionStorage.getItem("isLogin");
    }
    this.router.events.subscribe(
      (Data: any) => {
        // alert("test");
        
        if(sessionStorage.getItem('EmailId')!=null || sessionStorage.getItem('MobileNo')!=null){     
          this.FullName=sessionStorage.getItem('FullName');
          this.userEmail=sessionStorage.getItem('EmailId');
          this.userMobile=sessionStorage.getItem('MobileNo');
          this.isLogin =  sessionStorage.getItem("isLogin");
        }
        // this.sessionService.getContentData().subscribe((data: any) => {
        //   console.log(data);
        // });
      });
  // if(sessionStorage.getItem('email')!=null){
  //   this.authService.geUser(sessionStorage.getItem('email')).subscribe((res:User)=>{
  //     this.user=res[0].UserName;  
          
  //     debugger;
  // });
 }

 clearStorage(){
  // sessionStorage.clear();
  // localStorage.clear();

  // For Health Module, healthIndex Page Storage Start
  sessionStorage.removeItem('step2');
  sessionStorage.removeItem('step3');
  sessionStorage.removeItem('saveSessionStep1UserData');
  sessionStorage.removeItem('saveSessionStep2UserData');
  sessionStorage.removeItem('editMember');
  sessionStorage.removeItem('members');
  sessionStorage.removeItem('customer');
  // Health Storage End
 }

 
 userLogout(){
  sessionStorage.clear();
  this.FullName='';
  this.userEmail='';
  this.userMobile='';
  this.isLogin = '';
  this.router.navigate(['']);
}
}
