import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

import { BsDatepickerConfig } from "ngx-bootstrap/datepicker";
// import { ErrorHandle } from '../models/ErrorHandle';
import Swal from "sweetalert2";
import { Customer } from "../Models/Customer";
import { HealthservicesService } from "src/app/services/healthservices.service";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.css"],
})
export class ProfileComponent implements OnInit {
  DateOfBirth: string;
  profileForm: FormGroup;
  userName: string;
  userEmail: string;
  userMobile: string;
  loading = false;
  submitted = false;
  // ErrorHandle:ErrorHandle;
  datePickerConfig: Partial<BsDatepickerConfig>;
  CustomerEdit = new Customer();
  FirstTimeUser: boolean = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private healthMasterService: HealthservicesService
  ) {
    this.datePickerConfig = Object.assign({}, { containerClass: "theme-blue" });
  }

  ngOnInit() {
    this.profileForm = this.formBuilder.group({
      fullName: new FormControl(null, Validators.required),
      Email: new FormControl(null, [Validators.required, Validators.email]),
      Mobile: new FormControl(null, [
        Validators.required,
        Validators.pattern("[0-9]{10}"),
      ]),
      Gender: new FormControl(null, Validators.required),
      DateOfBirth: new FormControl(null, Validators.required),
      // Pincode: new FormControl(null, Validators.required),
      State: new FormControl(null, Validators.required),
      City: new FormControl(null, Validators.required),
      Address1: new FormControl(null, Validators.required),
      Address2: new FormControl(null),
      Landmark: new FormControl(null),
      AlternateMobileNo: new FormControl(null),
    });
    if (
      sessionStorage.getItem("EmailId") != null ||
      sessionStorage.getItem("MobileNo") != null
    ) {
      this.userEmail = sessionStorage.getItem("EmailId");
      this.GetUserProfile();
    } else {
      this.router.navigate(["/login"]);
    }
  }
  get r() {
    return this.profileForm.controls;
  }

  async updateProfile() {
    debugger;
    this.submitted = true;

    if (this.profileForm.invalid) {
      return;
    }

    console.log(this.profileForm);
    var val = {
      fullName: this.CustomerEdit.fullName,
      emailId: this.CustomerEdit.emailId,
      mobileNo: this.CustomerEdit.mobileNo,
      password: this.CustomerEdit.password,
      gender: this.CustomerEdit.gender,
      dateOfBirth: this.CustomerEdit.dateOfBirth,
      state: this.CustomerEdit.state,
      city: this.CustomerEdit.city,
      address: this.CustomerEdit.address,
      addressAlternate: this.CustomerEdit.addressAlternate,
      landmark: this.CustomerEdit.landmark,
      AlternateMobileNo: this.CustomerEdit.AlternateMobileNo,
      flag: "UpdateDetails",
    };

    await this.healthMasterService.UpdateCustomer(val).then(
      (Data: any) => {
        if (Data.length > 0) {
          Swal.fire("", Data, "success");
        }
        window.location.reload();
      },
      (error) => console.log("ApIerror=", error)
    );
  }

  async GetUserProfile() {
    debugger;

    var val = {
      userName: sessionStorage.getItem("MobileNo"),
      flag: 2,
    };

    await this.healthMasterService.GetCustomerDetails(val).then(
      (Data: any) => {
        if (Data.length > 0) {
          this.CustomerEdit.fullName = Data[0].Fullname;
          this.CustomerEdit.emailId = Data[0].EmailId;
          this.CustomerEdit.mobileNo = Data[0].MobileNo;
          this.CustomerEdit.password = Data[0].Password;
          this.CustomerEdit.gender = Data[0].Gender;
          this.CustomerEdit.dateOfBirth = Data[0].DateOfBirth;
          this.CustomerEdit.state = Data[0].State;
          this.CustomerEdit.city = Data[0].City;
          this.CustomerEdit.address = Data[0].Address;
          this.CustomerEdit.addressAlternate = Data[0].AddressAlternate;
          this.CustomerEdit.landmark = Data[0].Landmark;
          this.CustomerEdit.AlternateMobileNo = Data[0].AlternateMobileNo;
          sessionStorage.setItem("FullName", this.CustomerEdit.fullName);

          if (
            this.CustomerEdit.fullName &&
            this.CustomerEdit.fullName.trim() !== ""
          ) {
            this.FirstTimeUser = true;
          }
        }
      },
      (error) => console.log("ApIerror=", error)
    );
  }

  AlphaOnly($event) {
    debugger;
    var pattern = /^[a-zA-Z ]*$/;
    const inputChar = $event.key;
    if (!pattern.test(inputChar)) {
      //event.preventDefault();
      return false;
    }
    else
    {
      return true;
    }
  }

  onlyNumbers($event: KeyboardEvent): boolean {
    const pattern = /^[0-9]$/;
    const inputChar = $event.key;
  
    // Allow special keys like Backspace, Arrow keys, Delete, Tab, etc.
    if ($event.key === 'Backspace' || $event.key === 'ArrowLeft' || 
        $event.key === 'ArrowRight' || $event.key === 'Tab' || 
        $event.key === 'Delete') {
      return true;
    }
  
    // Check if the input character matches the numeric pattern
    if (!pattern.test(inputChar)) {
      $event.preventDefault();
      return false;
    }
    
    return true;
  }

  emailOnly($event: KeyboardEvent): boolean {
    // Allow alphanumeric characters, @, ., -, _, and special keys like Backspace, Arrow keys, Delete, etc.
    const pattern = /^[a-zA-Z0-9@._-]$/;
    const inputChar = $event.key;
  
    // Allow special keys like Backspace, Arrow keys, Delete, etc.
    if ($event.key === 'Backspace' || $event.key === 'ArrowLeft' || 
        $event.key === 'ArrowRight' || $event.key === 'Tab' || 
        $event.key === 'Delete') {
      return true;
    }
  
    // Check if the input character matches the allowed email pattern
    if (!pattern.test(inputChar)) {
      $event.preventDefault();
      return false;
    }
    return true;
  }
}
