export class twoWheelerDetails {
  RegistrationCode: string;
  RegistrationNo: string;
  ManufacturerId: number;
  ManufacturerName: string;
  ModelId: number;
  ModelName: string;
  VariantId: number;
  VariantName: string;
  SeatingCapacity: string;
  CubicCapacity: string;
  FuelId: number;
  FuelType: string;
  BodyType: string;
  VehicleSegment: string;
  ManufacturerYear: string;
  RegistationDate: string;
  CreatedDate: string;
  StateCode: number;
  CityAndRTO: string;
  PolicyType: string; /*TP OD Comprehensive*/
  PolicyWhichType: string; /*NEW ROLLOVER EXPIRY */
  PolicyExpiryDate: string;
}

export class QuotationModel {
  SessionId: string;
  UserId: string;
  CarOwnershipChange: string;
  ZeroDepcover: string;
  ClaimMade: string;
  LastNCB: string;
  CurrentNCB: string;
  OwnedBy: string;
  OwnerDOB: string;
  InsuredProfession: string;
  AutomobileMembership: string;
  MembershipNo: string;
  MembershipExpiry: string;
  AntiTheftDevices: string;
  VoluntryExcess: string;
  ElectricalAccessoriesValue: any;
  NonElectricalAccessoriesValue: any;
  CNGKit: string;
  CNGKitValue: string;
  FiberGlassTank: string;
  GeoGraphicalExtension: string;
  GeoGraphicalCountries: string;
  PaidDriver: string;
  RestrictTPPDLiability: string;
  PreviousPolicyExpirDate: string;
  PACover: string;
  NoOfPassengers: string;
  SumInsured: string = null;
  VoluntryAmount: string;
  IMT23: string;
  PACoverOwner: string;
  ExField1: string;
  ExField2: string;
  CurrentPolicyType: string;
  PreviousPolicyType: string;
  PolicyType: string;
  VehicleType: number;
  ManufacturerId: number;
  ManufacturerName: string;
  ModelId: number;
  ModelName: string;
  VariantId: number;
  VariantName: string;
  SeatingCapacity: string;
  CubicCapacity: string;
  FuelType: string;
  ManufacturerYear: string;
  CityOfRegistration: string;
  BuyerState: string;
  City: string;
  RegistrationCode: string;
  DateOfRegistration: string;
  DayOfPurchase: string;
  MonthOfPurchase: string;
  YearOfPurchase: string;
  vehicleEngineno: string;
  vehicleChassino: string;
  fla_vh_class_desc: string;
  Addons: addonMaster[];
  isPrevPolDtls: string = "";
  IDV: string = "";
  rdpa1: string;
  rdpa2: string;
  rdpa3: string;
  MobileNo: string;
}

export class premiumModel {
  SessionId: string;
  ProductId: string;
  ProductImage: string;
  MinimumIDV: string;
  MaximumIDV: string;
  ProductRating: string;
  ProductIDV: string;
  ProductPremium: string;
  ProductBasicPremium: string;
  BasicTPPremium: string;
  EAPremium: string;
  NEAPremium: string;
  CNGTP: string;
  CNGPremium: string;
  GEOPremium: string;
  FiberGlassPremium: string;
  ODPremium: string;
  TPPremium: string;
  AntiTheftDisc: string;
  VoluntryDisc: string;
  AutomobileMembers: string;
  NCBDisc: string;
  CommercialDisc: string;
  TotalDiscount: string;
  UnNamedPassengerTP: string;
  OwnerPA: string;
  PaidDriverTP: string;
  TPPDCover: string;
  TotalPAPremium: string;
  FinalODPremium: string;
  TotalTPPremium: string;

  TotalAddOnPremium: string;
  PackagePremium: string;
  ServiceTax: string;
  FinalPremium: string;
  DeTarriffDisc: string;
  ShowRoomPrice: string;
  GEOPremiumTP: string;
  Loading: string;
  Declined: string;
  ServiceTaxPercet: string;
  ZoneId: number;
  ClientOrderNo: String;
  ClientQuoteNo: String;
  WordingsDoc: String;
  BrochuresDoc: String;
  TermPlan: number;
  ErrorMessage: String;
  Addons: addon[];
  ManufacturerId: number;
  isNew: string;
  LongTermDisc: String;
  BreakinCharge: number;
  ProductName: string;
  CompanyId: number;
  CompanyName: string;
  FPPCover: number;
  NFPPCover: number;
  PlanId: number;
  ODStartDate: string;
  ODEndDate: string;
  TPStartDate: string;
  TPEndDate: string;
  Tenure: string;
  TPTenure: number;
  ODTenure: number;
  PolicyTenure: number;
  ProductPlanCode: string;
  TenureId: number;
}

export class addonMaster {
  AddOnId: number;
  VehicleTypeId: number;
  ADDONDescription: string;
}

export class addon extends addonMaster {
  SessionId: string;
  ProductId: string;
  IsStandard: string;
  AddOnPrice: number;
  Checked: boolean;
  PlanId: number;
}

export class InitiateCKYCResp {
  Status: string;
  StatusMessage: string;
  CKYCNumber: string = "";
  FullName: string;
  DOB: string;
  Name: string = "";
  LastName: string = "";
  MobileNo: string = "";
  Emailid: string = "";
  Address1: string = "";
  Address2: string = "";
}

export class DigitCKYCModel {
  ckycReferenceDocId: string;
  ckycReferenceNumber: string;
  dateOfBirth: string;
}

export class ClientDetails {
  FullName: string;
  MobileNumber: number;
}

export class InitiateCKYCTATA {
  Status: string;
  StatusMessage: string;
  CKYCNumber: string;
}

export class InitiateCKYCRespRS {
  applicationStatus: string;
  kycRefNo: string;
  uniqueId: string;
  dob: string;
  errMessage: string;
  returnURL: string;
  firstName: string;
  middleName: string;
  lastName: string;
  address1: string;
  address2: string;
  address3: string;
  city: string;
  district: string;
  state: string;
  country: string;
  pinCode: string;
  gender: string;
  fullName: string;
  mobile: string;
  email: string;
  pan: string;
  aadhaar: string;
  kyc_verified_date: string;
  customerType: string;
  companyName: string;
  gstin: string;
  cin: string;
  doi: string;
  companyAddress: string;
  companyCity: string;
  companyState: string;
  companyDistrict: string;
  loaVerification: string;
  ckycNo: string;
}

export class OTPResponse {
  StatusCode: string;
  Message: string;
}

export class InitiateCKYCGODIGITResp {
  Status: string;
  StatusMessage: string;
  CKYCNumber: string = "";
  FullName: string;
  DOB: string;
  Name: string = "";
  LastName: string = "";
  MobileNo: string = "";
  Emailid: string = "";
  Address1: string = "";
  Address2: string = "";
  Pincode: string = "";
  Gender: string = "";
}
