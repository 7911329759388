import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Observable, Subject } from "rxjs";
import { ErrorHandle } from "src/app/common/models/ErrorHandle";
import { MasterService } from "src/app/services/master.service";
import { MotorService } from "src/app/services/motor.service";
import Swal from "sweetalert2";
import { DateAdapter } from "@angular/material";
import { DatePipe } from "@angular/common";
import {
  twoWheelerDetails,
  QuotationModel,
  ClientDetails,
} from "../../common/Models/twoWheeler";
import { element } from "protractor";
declare var $: any;
@Component({
  selector: "app-car-rto",
  templateUrl: "./car-rto.component.html",
  styleUrls: ["./car-rto.component.css"],
})
export class CarRtoComponent implements OnInit {
  loading: boolean = false;
  ErrorHandle: ErrorHandle;
  divconstant: boolean = true;
  divPersonal: boolean = false;
  divRegistration: boolean = true;
  divManufacturer: boolean = false;
  divVarient: boolean = false;
  divModelVarient: boolean = false;
  divRegistrationYear: boolean = false;
  divPolicyType: boolean = false;
  divWhichPolicyType: boolean = true;
  divExpiredPolicy: boolean = false;
  divPersonal1fornew: boolean = false;
  vehicleDetails: twoWheelerDetails = new twoWheelerDetails();
  prevvehicleDetails: twoWheelerDetails = new twoWheelerDetails();
  CustomerDetails: ClientDetails = new ClientDetails();
  PopularCityOfRegistration = [];
  OrgCityOfRegistration = [];
  RegistrationCode = [];
  ManufacturerList = [];
  ModelsList = [];
  VariantList = [];
  RegistrationYearList = [];
  showBox = true;
  public clients: Observable<any[]>;
  private searchTerms = new Subject<string>();
  public ClientName = "";
  public flag: boolean = true;
  public codeValue: string;
  isdatalist: boolean = false;
  userList1 = [];
  states;
  isback: string;
  public pipe = new DatePipe("en-US");
  minDate: Date;
  maxDate: Date;
  keyword = "name";
  keywords = "VariantName";
  data = [];
  dataManufacturer = [];
  dataVarient = [];
  startPage: any;
  paginationLimit: any;
  VariantLists = [];
  NewVarientList: any = [];
  verifyotp: boolean = false;
  isMobileOTPVerified: boolean = false;
  submitbutton1: boolean = false;
  showOTPFields = false;
  otpSentTime: number = 0;
  showResendButton: boolean = false;
  countdown: number = 30;
  timerInterval: any;
  displayTime = "";

  constructor(
    private router: Router,
    private activeRouter: ActivatedRoute,
    private masterService: MasterService,
    private motorService: MotorService,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.dateAdapter.setLocale("en-GB");
    const currentDate = new Date();

    this.minDate = new Date();
    this.maxDate = currentDate;
    this.minDate.setFullYear(currentDate.getFullYear() - 15);
  }

  onClickedOutside(e: Event) {
    debugger;
    this.showBox = false;
  }

  ngOnInit() {
    this.loading = true;
    this.startPage = 0;
    this.paginationLimit = 12;
    this.divPolicyType = false;
    this.divWhichPolicyType = true;
    if (sessionStorage.getItem("vehicledata") != null) {
      this.vehicleDetails = JSON.parse(sessionStorage.getItem("vehicledata"));
    }
    this.isback = this.activeRouter.snapshot.queryParamMap.get("isback");

    this.goMotorVehicleData();
    this.GetPopularCityOfRegistration();
    if (this.isback == "Yes") {
      this.goDivRegistrationYear(this.vehicleDetails.VariantId);
    }
  }

  goRegistrationCode(CityId) {
    $("#div_" + sessionStorage.getItem("RegCode"))
      .children(".rto_codes")
      .hide();
    $("#div_" + CityId)
      .children(".rto_codes")
      .toggle();

    sessionStorage.removeItem("RegCode");
    sessionStorage.setItem("RegCode", CityId);
  }

  goDivRegistration() {
    this.divRegistration = true;
    this.divManufacturer = false;
    this.divVarient = false;
    this.divModelVarient = false;
    this.divRegistrationYear = false;
    this.divExpiredPolicy = false;
  }
  goDivManufacturer(regCode) {
    this.paginationLimit = 12;
    this.divRegistration = false;
    this.divVarient = false;
    this.divModelVarient = false;
    this.divRegistrationYear = false;
    this.divExpiredPolicy = false;
    this.divManufacturer = true;
    this.data = [];
    if (regCode != "") {
      let VehicleType = 5;
      this.vehicleDetails.RegistrationNo = regCode.toUpperCase();
      this.vehicleDetails.RegistrationCode = regCode.toUpperCase();
      sessionStorage.setItem("registrationNumber", regCode.toUpperCase());
      this.getCity(regCode);
      this.masterService.GetPopularManufacturer(VehicleType).subscribe(
        (data: any[]) => {
          this.ManufacturerList = data;
        },
        (err: ErrorHandle) => {
          this.ErrorHandle = err;
          if (this.ErrorHandle.statusText == "Bad Request") {
            Swal.fire("Please contact administrator.", "", "error");
          }
        }
      );
    }
    this.searchClientManufacture("");
  }

  goDivVarient(ManufacturerId, ManufacturerName) {
    debugger;
    if (ManufacturerId != "") {
      this.vehicleDetails.ManufacturerId = ManufacturerId;
      this.vehicleDetails.ManufacturerName = ManufacturerName;
      if (this.isback != "Yes") {
        this.vehicleDetails.ModelId = 0;
        this.vehicleDetails.ModelName = "";
        this.vehicleDetails.VariantId = 0;
        this.vehicleDetails.VariantName = "";
        this.VariantList = [];
      } else {
        $(".first").addClass("in");
      }
    }
    this.divRegistration = false;
    this.divManufacturer = false;
    this.divRegistrationYear = false;
    this.divExpiredPolicy = false;
    this.divVarient = true;

    this.masterService
      .GetPopularVehicleModels(this.vehicleDetails.ManufacturerId)
      .subscribe(
        (data: any[]) => {
          this.ModelsList = data;
        },
        (err: ErrorHandle) => {
          this.ErrorHandle = err;
          if (this.ErrorHandle.statusText == "Bad Request") {
            Swal.fire("Please contact administrator.", "", "error");
          }
        }
      );
    this.searchClientVarient("");
  }

  selectEventVarientList(e) {
    debugger;
    this.NewVarientList = e;
    this.vehicleDetails.VariantId = e.VehicleVariantId;
    this.vehicleDetails.VariantName = e.VariantName;
    this.vehicleDetails.SeatingCapacity = e.SeatingCapacity;
    this.vehicleDetails.CubicCapacity = e.CubicCapacity;
    this.vehicleDetails.FuelType = e.FuelType;
    this.divModelVarient = false;
    this.divRegistrationYear = false;
    //this.govariantList(this.vehicleDetails.ModelId, this.vehicleDetails.ModelName);
    $(".first").addClass("in");
    this.goDivRegistrationYear(this.NewVarientList);
  }

  goDivRegistrationYear(Variant) {
    debugger;
    if (this.isback != "Yes") {
      this.vehicleDetails.VariantId = Variant.VehicleVariantId;
      this.vehicleDetails.VariantName = Variant.VariantName;
      this.vehicleDetails.SeatingCapacity = Variant.SeatingCapacity;
      this.vehicleDetails.CubicCapacity = Variant.CubicCapacity;
      this.vehicleDetails.FuelType = Variant.FuelType;
    }

    this.RegistrationYearList = [];
    this.divRegistration = false;
    this.divManufacturer = false;
    this.divVarient = false;
    this.divModelVarient = false;
    this.divExpiredPolicy = false;

    let currentyear = new Date().getFullYear();
    let i = currentyear - 15;

    for (i; i < currentyear; i++) {
      this.RegistrationYearList.push({ year: i });
    }
    this.RegistrationYearList.push({ year: currentyear });
    //console.log(this.RegistrationYearList);
    //this.RegistrationYearList.sort((a,b) =>  a < b ? 1 : a > b ? -1 : 0)

    this.RegistrationYearList.sort(function (a, b) {
      return b.year - a.year;
    });

    console.log(this.RegistrationYearList);
    let id = this.activeRouter.snapshot.queryParamMap.get("type");
    if (id == "NEW") {
       this.divRegistrationYear= false;
      this.divPersonal1fornew = true;
      // this.goDivExpiredPolicy(currentyear);
    }
    // else if (id == "NEW" && this.divPersonal1fornew == true){
    //   this.goDivExpiredPolicy(currentyear);
    // }
    else {
      this.divconstant = true;
      this.divRegistrationYear = true;
    }
  }
  gotodivnewbundle(){
    let currentyear = new Date().getFullYear();
    let i = currentyear - 15;

    for (i; i < currentyear; i++) {
      this.RegistrationYearList.push({ year: i });
    }
    this.RegistrationYearList.push({ year: currentyear });
    //console.log(this.RegistrationYearList);
    //this.RegistrationYearList.sort((a,b) =>  a < b ? 1 : a > b ? -1 : 0)

    this.RegistrationYearList.sort(function (a, b) { return b.year - a.year });

    console.log(this.RegistrationYearList);
    let id = this.activeRouter.snapshot.queryParamMap.get('type');
    if (id == "NEW") {
      // this.divPersonal1fornew = true;
      this.goDivExpiredPolicy(currentyear);
    }
  }
  goDivPersonal(regCode) {
    this.divPersonal = true;
    this.divRegistration = false;
    this.divManufacturer = false;
    this.divVarient = false;
    this.divModelVarient = false;
    this.divExpiredPolicy = false;
    this.divRegistrationYear = false;
    if (regCode != "") {
      // let VehicleType = 5
      this.vehicleDetails.RegistrationNo = regCode.toUpperCase();
      this.vehicleDetails.RegistrationCode = regCode.toUpperCase();
      sessionStorage.setItem("registrationNumber", regCode.toUpperCase());
      this.getCity(regCode);
    }
  }
  goDivExpiredPolicy(regYear) {
    debugger;
    this.divconstant = false;
    this.divRegistration = false;
    this.divManufacturer = false;
    this.divVarient = false;
    this.divModelVarient = false;
    this.divRegistrationYear = false;
    this.divExpiredPolicy = true;
    let rgdate = new Date();
    if (regYear != null) {
      if (regYear != undefined) {
        if (regYear.toString().includes("/")) {
          let dateParts = regYear.split("/");
          // month is 0-based, that's why we need dataParts[1] - 1
          let dateObject = new Date(
            +dateParts[2],
            dateParts[1] - 1,
            +dateParts[0]
          );
          rgdate = new Date(dateObject);
        } else {
          rgdate = new Date(regYear);
        }
      }
    }
    let year = rgdate.getFullYear();
    let month = rgdate.getMonth() + 1;
    let day = rgdate.getDay();

    this.vehicleDetails.ManufacturerYear = year.toString();
    // var d = new Date();
    // var n = d.getMonth()+1;
    debugger;
    //let pipe = new DatePipe('en-US');
    this.vehicleDetails.RegistationDate = this.pipe.transform(
      rgdate,
      "yyyy-MM-dd"
    );
    // $('#myModal').show();
    //  if(this.isback!='Yes')
    //   {
    this.saveQuote();
    // }
  }

  DivExpiredPolicyClick(regYear) {
    debugger;
    this.SaveRiskcareUser();
    this.goDivExpiredPolicy(regYear);

    // if (
    //   this.vehicleDetails.RegistationDate == "" ||
    //   this.vehicleDetails.RegistationDate == null ||
    //   this.vehicleDetails.RegistationDate == undefined
    // ) {
    //   Swal.fire("Please select Registration Date", "", "error");
    //   return false;
    // } else if (
    //   this.CustomerDetails.FullName == "" ||
    //   this.CustomerDetails.FullName == null ||
    //   this.CustomerDetails.FullName == undefined
    // ) {
    //   Swal.fire("Please Enter The Full Name", "", "error");
    //   return false;
    // } else if (this.CustomerDetails.FullName != null) {
    //   var result = this.AlphaOnly(this.CustomerDetails.FullName);
    //   if (result == false) {
    //     Swal.fire("Please enter the name in alphabates only", "", "error");
    //     return false;
    //   } else if (
    //     this.CustomerDetails.MobileNumber == null ||
    //     this.CustomerDetails.MobileNumber == undefined
    //   ) {
    //     Swal.fire("Please Enter The Mobile Number", "", "error");
    //   } else if (
    //     this.CustomerDetails.MobileNumber != null ||
    //     this.CustomerDetails.MobileNumber.toString().length < 10
    //   ) {
    //     var temp = this.onlyNumber(this.CustomerDetails.MobileNumber);
    //     if (temp == false) {
    //       Swal.fire("Please Enter Valid Mobile Number", "", "error");
    //       return false;
    //     } else {
    //       this.goDivExpiredPolicy(regYear);
    //     }
    //   }
    // }
  }

  getWhichType(policyWhichType) {
    let dtExp = new Date();
    let d = dtExp.getDay();

    let year = dtExp.getFullYear();
    let mnth = dtExp.getMonth() + 1;
    this.divPolicyType = true;
    this.divWhichPolicyType = false;
    if (policyWhichType == "RollOver") {
      debugger;
      dtExp.setDate(dtExp.getDate() - 1);
      d = dtExp.getDate();
      year = dtExp.getFullYear();
      mnth = dtExp.getMonth() + 1;

      let dd = d.toString().length == 1 ? "0" + d : d.toString();
      let mm = mnth.toString().length == 1 ? "0" + mnth : mnth.toString();
      this.vehicleDetails.PolicyExpiryDate = year + "-" + mm + "-" + dd;
      this.vehicleDetails.PolicyWhichType = "RollOver";
    } else if (policyWhichType == "Expired90") {
      dtExp.setDate(dtExp.getDate() - 10);
      d = dtExp.getDate();
      year = dtExp.getFullYear();
      mnth = dtExp.getMonth() + 1;
      let dd = d.toString().length == 1 ? "0" + d : d.toString();
      let mm = mnth.toString().length == 1 ? "0" + mnth : mnth.toString();
      this.vehicleDetails.PolicyExpiryDate = year + "-" + mm + "-" + dd;
      this.vehicleDetails.PolicyWhichType = "Expired";
    } else if (policyWhichType == "ExpiredMore90") {
      dtExp.setDate(dtExp.getDate() - 91);
      d = dtExp.getDate();
      year = dtExp.getFullYear();
      mnth = dtExp.getMonth() + 1;
      let dd = d.toString().length == 1 ? "0" + d : d.toString();
      let mm = mnth.toString().length == 1 ? "0" + mnth : mnth.toString();
      this.vehicleDetails.PolicyExpiryDate = year + "-" + mm + "-" + dd;
      this.vehicleDetails.PolicyWhichType = "Expired";
    } else if (policyWhichType == "ExpiredNotRemeber") {
      this.vehicleDetails.PolicyExpiryDate = "";
      this.vehicleDetails.PolicyWhichType = "Expired";
    }
  }

  goPolicyType(policyType) {
    this.vehicleDetails.PolicyType = policyType;
    this.saveQuote();
  }
  registrationCode: any;
  getCity(rtoCode) {
    this.masterService.GetCityOfRegistration(rtoCode).subscribe((result) => {
      this.registrationCode = result;
      sessionStorage.setItem("RegCode", result[0].CityId);
    });
  }
  saveQuote() {
    debugger;
    let res = sessionStorage.getItem("vehicledata");
    let id = this.activeRouter.snapshot.queryParamMap.get("type");
    if (res == null) {
      sessionStorage.setItem(
        "vehicledata",
        JSON.stringify(this.vehicleDetails)
      );
    } else {
      this.prevvehicleDetails = JSON.parse(
        sessionStorage.getItem("vehicledata")
      );
      if (this.vehicleDetails.VariantId == undefined) {
        this.vehicleDetails.VariantId = this.prevvehicleDetails.VariantId;
        this.vehicleDetails.VariantName = this.prevvehicleDetails.VariantName;
        this.vehicleDetails.SeatingCapacity =
          this.prevvehicleDetails.SeatingCapacity;
        this.vehicleDetails.CubicCapacity =
          this.prevvehicleDetails.CubicCapacity;
      } else if (
        this.prevvehicleDetails.VariantId != this.vehicleDetails.VariantId
      ) {
        sessionStorage.setItem(
          "vehicledata",
          JSON.stringify(this.vehicleDetails)
        );
      }
      if (this.vehicleDetails.ModelId == undefined) {
        this.vehicleDetails.ModelId = this.prevvehicleDetails.ModelId;
      } else if (
        this.prevvehicleDetails.ModelId != this.vehicleDetails.ModelId
      ) {
        sessionStorage.setItem(
          "vehicledata",
          JSON.stringify(this.vehicleDetails)
        );
      }
      if (this.vehicleDetails.ManufacturerYear == undefined) {
        this.vehicleDetails.ManufacturerYear =
          this.prevvehicleDetails.ManufacturerYear;
      } else if (
        this.prevvehicleDetails.ManufacturerYear !=
        this.vehicleDetails.ManufacturerYear
      ) {
        sessionStorage.setItem(
          "vehicledata",
          JSON.stringify(this.vehicleDetails)
        );
      }
      if (this.vehicleDetails.ManufacturerId == undefined) {
        this.vehicleDetails.ManufacturerId =
          this.prevvehicleDetails.ManufacturerId;
      } else if (
        this.prevvehicleDetails.ManufacturerId !=
        this.vehicleDetails.ManufacturerId
      ) {
        sessionStorage.setItem(
          "vehicledata",
          JSON.stringify(this.vehicleDetails)
        );
      }
      if (this.vehicleDetails.RegistrationNo == undefined) {
        this.vehicleDetails.RegistrationNo =
          this.prevvehicleDetails.RegistrationNo;
      } else if (
        this.prevvehicleDetails.RegistrationNo !=
        this.vehicleDetails.RegistrationNo
      ) {
        sessionStorage.setItem(
          "vehicledata",
          JSON.stringify(this.vehicleDetails)
        );
      }
    }
    //sessionStorage.setItem('vehicledata',JSON.stringify(this.vehicleDetails));

    debugger;
    const quoteModel = new QuotationModel();
    quoteModel.UserId = "";
    quoteModel.ManufacturerId = this.vehicleDetails.ManufacturerId;
    quoteModel.ModelId = this.vehicleDetails.ModelId;
    quoteModel.VariantId = this.vehicleDetails.VariantId;
    quoteModel.SeatingCapacity = this.vehicleDetails.SeatingCapacity;
    quoteModel.CubicCapacity = this.vehicleDetails.CubicCapacity;
    quoteModel.FuelType = this.vehicleDetails.FuelType;
    //  quoteModel.VehicleType=3;//Two Wheeler
    quoteModel.VehicleType = 5; //Pvt Car
    quoteModel.BuyerState = this.vehicleDetails.RegistrationNo.substr(0, 2);
    if (id == "NEW") {
      quoteModel.ManufacturerYear = new Date().getFullYear().toString();
    } else {
      quoteModel.ManufacturerYear = this.vehicleDetails.ManufacturerYear;
    }
    quoteModel.DateOfRegistration = this.vehicleDetails.RegistationDate;
    quoteModel.RegistrationCode = this.vehicleDetails.RegistrationNo;
    if (id == "NEW") {
      quoteModel.DayOfPurchase = this.pipe
        .transform(new Date(), "yyyy-MM-dd")
        .split("-")[2];
      quoteModel.MonthOfPurchase = this.pipe
        .transform(new Date(), "yyyy-MM-dd")
        .split("-")[1];
      quoteModel.YearOfPurchase = this.pipe
        .transform(new Date(), "yyyy-MM-dd")
        .split("-")[0];
    } else {
      quoteModel.DayOfPurchase =
        this.vehicleDetails.RegistationDate.split("-")[2];
      quoteModel.MonthOfPurchase =
        this.vehicleDetails.RegistationDate.split("-")[1];
      quoteModel.YearOfPurchase =
        this.vehicleDetails.RegistationDate.split("-")[0];
    }

    if (id == "NEW") quoteModel.CurrentPolicyType = "NEW";
    else quoteModel.CurrentPolicyType = "RollOver";
    //quoteModel.PolicyType=this.vehicleDetails.PolicyType;
    quoteModel.PolicyType = "Comprehensive";
    quoteModel.PreviousPolicyType = "Comprehensive"; //this.vehicleDetails.PolicyType;
    if (
      quoteModel.RegistrationCode != "" &&
      sessionStorage.getItem("RegCode") == ""
    ) {
      this.getCity(quoteModel.RegistrationCode);
    }
    debugger;
    quoteModel.CityOfRegistration = sessionStorage.getItem("RegCode");
    quoteModel.ClaimMade = "Yes";
    quoteModel.AutomobileMembership = "No";
    quoteModel.LastNCB = null;
    quoteModel.PACoverOwner = "No";
    quoteModel.OwnedBy = "Individual";
    quoteModel.AntiTheftDevices = "No";
    if (quoteModel.FuelType == "CNG" || quoteModel.FuelType == "LPG") {
      quoteModel.CNGKit = "Company Fitted";
    } else {
      quoteModel.CNGKit = "Not Fitted";
    }

    quoteModel.GeoGraphicalExtension = "No";
    quoteModel.PaidDriver = "No";
    quoteModel.RestrictTPPDLiability = "No";
    quoteModel.PACover = "No";
    quoteModel.PreviousPolicyExpirDate = this.vehicleDetails.PolicyExpiryDate;
    this.motorService.postQuoteData(quoteModel).subscribe((result: any) => {
      if (result && result.status == "done")
        sessionStorage.setItem("sessionId", result.Id);

      this.router.navigate(["/car-coverage"], {
        queryParams: { sessionId: result.Id },
      });
      // this.router.navigateByUrl('/two-wheeler-quotation?sessionId='+result.Id);
    });
  }
  goRegistrationPage() {
    this.router.navigateByUrl("/car-insurance");
  }

  goMotorVehicleData() {
    this.vehicleDetails.RegistrationNo = "";
    this.vehicleDetails.ManufacturerName = "";
    this.vehicleDetails.ModelName = "";
    this.vehicleDetails.VariantName = "";
    let registrationNumber = sessionStorage.getItem("registrationNumber");
    if (registrationNumber != "") {
      if (this.vehicleDetails.RegistrationCode != null) {
        this.goDivManufacturer(this.vehicleDetails.RegistrationCode);
      }
    }
    // this.masterService.getRegistrationDetails(registrationNumber).subscribe((data: any[])=>{
    this.motorService.getflaVehicleByRegNo(registrationNumber).subscribe(
      (data: QuotationModel) => {
        debugger;
        // if(data.length>0){
        if (data) {
          this.vehicleDetails = new twoWheelerDetails();
          // this.vehicleDetails.RegistrationNo=data[0].RegistrationNo;
          this.vehicleDetails.RegistrationNo = registrationNumber;
          this.vehicleDetails.ManufacturerName = data.ManufacturerName;
          this.vehicleDetails.ModelName = data.ModelName;
          this.vehicleDetails.VariantName = data.VariantName;
          this.vehicleDetails.FuelType = data.FuelType;
          this.vehicleDetails.ManufacturerYear = data.ManufacturerYear;
          // this.vehicleDetails.RegistationDate=data[0].RegistationDate;
          this.vehicleDetails.RegistationDate = data.DateOfRegistration;
          // this.goDivExpiredPolicy(this.vehicleDetails.ManufacturerYear);
          this.goDivExpiredPolicy(this.vehicleDetails.RegistationDate);
        }
        this.loading = false;
      },
      (err: ErrorHandle) => {
        this.loading = false;
        this.ErrorHandle = err;
        if (this.ErrorHandle.statusText == "Bad Request") {
          Swal.fire("Please contact administrator.", "", "error");
        }
      }
    );
  }
  GetPopularCityOfRegistration() {
    this.divRegistration = true;
    this.masterService.GetPopularCityOfRegistration().subscribe(
      (data: any[]) => {
        this.OrgCityOfRegistration = data;
        console.log(this.OrgCityOfRegistration);
        this.PopularCityOfRegistration = data.filter(
          (v, i, a) => a.findIndex((t) => t.CityId === v.CityId) === i
        );
        console.log(this.PopularCityOfRegistration);
      },
      (err: ErrorHandle) => {
        this.ErrorHandle = err;
        if (this.ErrorHandle.statusText == "Bad Request") {
          Swal.fire("Please contact administrator.", "", "error");
        }
      }
    );
  }
  filterList(CityId) {
    return (this.RegistrationCode = this.OrgCityOfRegistration.filter(
      (x) => x.CityId == CityId
    ));
  }
  govariantList(ModelId, ModelName) {
    debugger;
    if (ModelId != "") {
      this.vehicleDetails.ModelId = ModelId;
      this.vehicleDetails.ModelName = ModelName;
    }
    this.divVarient = false;
    this.divModelVarient = true;
    this.masterService.GetVariant(this.vehicleDetails.ModelId).subscribe(
      (data: any[]) => {
        let getdata = [];
        // for (let i = 0; i < data.length; i++) {
        //   getdata.push({ id: data[i].VehicleVariantId, name: data[i].VariantName + ' (' + data[i].VehicleVariantId + ')', address: data[i].VariantName });
        //   this.VariantLists = getdata;

        // }
        for (let i = 0; i < data.length; i++) {
          getdata.push({
            vhiclevariantid: data[i].VehicleVariantId,
            name: data[i].VariantName + " (" + data[i].VehicleVariantId + ")",
            address: data[i].VariantName,
          });
          this.VariantLists = getdata;
        }
        console.log("OldVarientListsss", this.VariantLists);
        //  this.NewVarientList = data;
        this.VariantList = data;
        // console.log('NewVarientList',this.NewVarientList);
        console.log("WorkingVarientList", this.VariantList);
      },
      (err: ErrorHandle) => {
        this.ErrorHandle = err;
        if (this.ErrorHandle.statusText == "Bad Request") {
          Swal.fire("Please contact administrator.", "", "error");
        }
      }
    );
  }

  searchClient(term): void {
    let searchText = term.target.value; //(<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    this.data = [];

    if (searchText.length > 1) {
      this.masterService
        .GetUnPopularCityOfRegistration(searchText)
        .subscribe((vdata: any[]) => {
          let getdata = [];

          for (let i = 0; i < vdata.length; i++) {
            getdata.push({
              id: vdata[i].CityId,
              name: vdata[i].CityName + " (" + vdata[i].RegistrationCode + ")",
              address: vdata[i].CityName + " " + vdata[i].RegistrationCode,
            });
            console.log(vdata[i].RegistrationCode);
          }
          this.data = getdata;
        });
      // this.masterService.GetRCBSCityRegistration(searchText).subscribe((vdata: any[])=>{
      //   let getdata=[];

      //   for (let i = 0; i < vdata.length; i++) {
      //     getdata.push({id:vdata[i].CItyId,name: vdata[i].CItyName+' ('+vdata[i].RegionCode+')',address: vdata[i].CItyName+' '+vdata[i].RegionCode});
      //     console.log(vdata[i].RegionCode);
      //   }
      //   this.data=getdata;

      // })
    }
    this.searchTerms.next(term);
  }
  onFocused(term) {
    let searchText = term.target.value; //(<HTMLInputElement>document.getElementById('userIdFirstWay')).value;
    this.data = [];
    if (searchText == "") searchText = "delhi";
    this.masterService
      .GetUnPopularCityOfRegistration(searchText)
      .subscribe((vdata: any[]) => {
        this.data = vdata;
      });
  }
  selectEvent(e) {
    debugger;
    // e.address = e.address.split(' ')[1];
    e.address = e.address.split(",");
    e.address = e.address[1];

    debugger;
    // let address: [] = e.address.split(' ');
    // console.log(address.length)
    // if (address.length <3) {
    //   e.address = e.address.split(' ')[1];
    //   e.address = e.address.split(',');
    //   e.address = e.address[1];
    // }
    // else {
    //   e.address = e.address.split(' ')[2];
    //   e.address = e.address.split(',');
    //   e.address = e.address[1];
    // }

    this.vehicleDetails.RegistrationNo = e.address;
    this.vehicleDetails.CityAndRTO = e.name;
    this.goDivManufacturer(e.address);
  }

  othersManufacturer() {
    this.paginationLimit = Number(this.paginationLimit) + 100;
  }

  getQuotes() {
    console.log(this.vehicleDetails);
    this.router.navigateByUrl("/car-quotation");
  }

  selectEventManufacture(e) {
    this.vehicleDetails.ManufacturerId = e.id;
    this.vehicleDetails.ManufacturerName = e.address;

    this.goDivVarient(
      this.vehicleDetails.ManufacturerId,
      this.vehicleDetails.ManufacturerName
    );
  }

  searchClientManufacture(term): void {
    this.dataManufacturer = [];
    // let VehicleType=3//Two Wheeler
    let VehicleType = 5; //Pvt Car
    this.masterService.GetManufacturer(VehicleType).subscribe((data: any[]) => {
      let getdata = [];
      for (let i = 0; i < data.length; i++) {
        getdata.push({
          id: data[i].ManufacturerId,
          name: data[i].ManufacturerName + " (" + data[i].ManufacturerId + ")",
          address: data[i].ManufacturerName,
        });
        console.log(data[i].ManufacturerName);
      }
      this.dataManufacturer = getdata;
    });
  }

  selectEventVarient(e) {
    debugger;
    this.vehicleDetails.ModelId = e.id;
    this.vehicleDetails.ModelName = e.address;
    this.govariantList(
      this.vehicleDetails.ModelId,
      this.vehicleDetails.ModelName
    );
    $(".first").addClass("in");
  }
  searchClientVarient(term): void {
    this.dataVarient = [];
    this.masterService
      .GetPopularVehicleModels(this.vehicleDetails.ManufacturerId)
      .subscribe((data: any[]) => {
        let getdata = [];
        for (let i = 0; i < data.length; i++) {
          getdata.push({
            id: data[i].ModelId,
            name: data[i].ModelName + " (" + data[i].ModelId + ")",
            address: data[i].ModelName,
          });
          console.log(data[i].ManufacturerName);
        }
        this.dataVarient = getdata;
      });
  }

  openNewpopup() {
    $("#Newpopup").modal("show");
    // $(".modal-backdrop").removeClass('modal-backdrop fade show');
  }
  openNewpopup1() {
    $("#Newpopup1").modal("show");
    // $(".modal-backdrop").removeClass('modal-backdrop fade show');
  }
  openNewpopup2() {
    $("#Newpopup2").modal("show");
    // $(".modal-backdrop").removeClass('modal-backdrop fade show');
  }

  onlyNumber($event) {
    debugger;
    const pattern = /^[0-9]*$/;
    const inputChar = $event;
    if (!pattern.test(inputChar.toString())) {
      // invalid character, prevent input
      //event.preventDefault();
      return false;
    } else {
      const regex = /^[6-9]\d{9}$/gm;
      const str = $event;
      let m;
      if ((m = regex.exec(str) == null)) {
        return false;
      } else {
        return true;
      }
    }
  }

  AlphaOnly($event) {
    debugger;
    var pattern = /^[a-zA-Z ]*$/;
    const inputChar = $event.key;
    if (!pattern.test(inputChar)) {
      //event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  onlyNumbers($event) {
    const pattern = /[0-9]/;
    const inputChar = $event.key;
    if (!pattern.test(inputChar.toString())) {
      // invalid character, prevent input
      //event.preventDefault();
      return false;
    }
  }

  SaveRiskcareUser() {
    debugger;

    sessionStorage.setItem("CustomerEmail", $("#Email").val());
    sessionStorage.setItem("CustomerMobile", $("#Mobile").val());

    this.motorService
      .SaveNewUser($("#Name").val(), $("#Email").val(), $("#Mobile").val())
      .subscribe((res: any) => {
        if (res == true) {
          let data = res;
          // Swal.fire('Thank you for providing your details. Our team will contact you soon.', '', 'success');
        }
      });
  }

  captureOTP(OTPType: any) {
    // debugger;
    // // this.submitted = true;

    // if (
    //   OTPType == "P" &&
    //   ($("#Name").val() == "" || $("#Name").val() == undefined)
    // ) {
    //   Swal.fire("", "Please Enter Your Name", "error");
    //   return;
    // } else 
    // {
    //   if (
    //     OTPType == "P" &&
    //     ($("#Mobile").val() == "" || $("#Mobile").val() == undefined)
    //   ) {
    //     Swal.fire("", "Please Enter Valid Mobile Number", "error");
    //   } else if (OTPType == "P" && $("#Mobile").val().length < 10) {
    //     Swal.fire("", "Mobile Number should be 10 digits", "error");
    //   } else {
    //     this.SaveRiskcareUser();

    //     var val = {
    //       IsActive: true,
    //       // Name: $("#Name").val(),
    //       UserId: $("#Mobile").val(),
    //       MobileNo: $("#Mobile").val(),
    //       OTPExpiry: "",
    //       EmailId: $("#Email").val(),
    //       Remarks: OTPType,
    //       Status: "",
    //       CreatedBy: "",
    //       CreatedByIP: "",
    //     };

    //     // this.spinner.show();

    //     this.motorService.SendOTP(val).subscribe((data: any) => {
    //       debugger;
    //       if (data.Status == "SUCCESS") {
    //         // this.otpBox = "Y";
    //         // this.phoneBox = "N";
    //         if (OTPType == "P")
    //           Swal.fire("", "OTP sent to mobile number", "success");
    //         this.verifyotp = true;
    //         this.otpSentTime = Date.now();
    //         this.startCountdown();
    //       } else {
    //         Swal.fire("", data.ErrorMessage || "OTP sending failed", "error");
    //         this.verifyotp = false;
    //       }
    //     });
    //   }
    // }
    this.submitbutton1 = true;
  }

  // OTPValidation(OTPType: any) {
  //   // this.submitted = true;

  //   if (
  //     OTPType == "P" &&
  //     ($("#MOTP").val() == "" || $("#MOTP").val() == undefined)
  //   ) {
  //     Swal.fire("", "Kindly Enter OTP sent on your Mobile number", "error");
  //     return;
  //   }

  //   // if (OTPType == 'E' && (this.EmailOTP == '' || this.EmailOTP == undefined)) {
  //   //   Swal.fire("", "Kindly Enter OTP sent on your Email id", "error");
  //   //   return;
  //   // }

  //   var val = {
  //     IsActive: true,
  //     UserId: $("#Mobile").val(),
  //     MobileNo: $("#Mobile").val(),
  //     OTPExpiry: "",
  //     EmailId: $("#Email").val(),
  //     Remarks: "",
  //     Status: "",
  //     CreatedBy: "",
  //     CreatedByIP: "",
  //   };
  //   this.motorService.OTPValidation(val).subscribe((data: any) => {
  //     debugger;

  //     if (OTPType == "P" && $("#MOTP").val() == data[0].OTP) {
  //       Swal.fire("", "Mobile OTP Verified", "success");
  //       this.isMobileOTPVerified = true;

  //       this.submitbutton1 = true;
  //     }
  //     // else if (OTPType == 'E' && (this.EmailOTP == data[0].EOTP)) {
  //     //   //Swal.fire("", "Email OTP is correct", "success");
  //     //   this.isEmailOTPVerified = true;
  //     // }
  //     else if (OTPType == "P" && $("#MOTP").val() != data[0].OTP) {
  //       Swal.fire("", "Mobile OTP is not correct", "error");
  //     }
  //     // else if (OTPType == 'E' && (this.EmailOTP != data[0].EOTP)) {
  //     //   Swal.fire("", "Email OTP is not correct", "error");
  //     // }
  //     else {
  //       Swal.fire("", "Please provide correct OTP", "error");
  //     }

  //     //}
  //   });

  //   ////
  // }

  OTPValidation(OTPType: any) {
    // debugger;
    // if (OTPType === "P" && ($("#MOTP").val() === "" || $("#MOTP").val() === undefined)) {
    //     Swal.fire("", "Kindly Enter OTP sent on your Mobile number", "error");
    //     return;
    // }

    // var val = {
    //     IsActive: true,
    //     UserId: $("#Mobile").val(),
    //     MobileNo: $("#Mobile").val(),
    //     OTP: $("#MOTP").val(), 
    //     EmailId: $("#Email").val(),
    //     Remarks: "",
    //     Status: "",
    //     CreatedBy: "",
    //     CreatedByIP: "",
    // };
    
    // sessionStorage.setItem("MobileNo", $("#Mobile").val());

    // this.motorService.OTPValidation(val).subscribe((data: any) => {
    //     debugger;

    //     if (data.success) {
    //         Swal.fire("", "OTP Verified", "success");
    //         this.isMobileOTPVerified = true;
    //         this.submitbutton1 = true;
    //     } else {
    //         Swal.fire("", data.message || "Please provide correct OTP", "error");
    //     }
    // });

    this.submitbutton1 = true;
}

  startCountdown() {
    this.countdown = 60;
    this.showResendButton = false;
    this.timerInterval = setInterval(() => {
      this.countdown--;

      if (this.countdown <= 0) {
        clearInterval(this.timerInterval);
        this.showResendButton = true;
        this.countdown = 0;
      }
      const minutes = Math.floor(this.countdown / 60);
      const seconds = this.countdown % 60;
      this.displayTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    }, 1000);
  }

  ngOnDestroy() {
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }
}
