import { Component, OnInit } from "@angular/core";
import { HealthservicesService } from "../../services/healthservices.service";
import { ContactUs } from "../../common/Models/healthMaster.models";
import Swal from "sweetalert2";
import { handleError } from "src/app/Reusable/Modal";

@Component({
  selector: "app-contact",
  templateUrl: "./contact.component.html",
  styleUrls: ["./contact.component.css"],
})
export class ContactComponent implements OnInit {
  ContactUs: any = new ContactUs();
  constructor(private healthMasterService: HealthservicesService) {}

  ngOnInit(): void {}

  validateform() {
    debugger;
    if (
      this.ContactUs.Name == "" ||
      this.ContactUs.Name == undefined ||
      this.ContactUs.Name == null
    ) {
      handleError("Full Name", "Field is Mandatory");
    } else if (
      this.ContactUs.Email == "" ||
      this.ContactUs.Email == undefined ||
      this.ContactUs.Email == null
    ) {
      handleError("Email", "Field is Mandatory");
    } else if (
      this.ContactUs.Mobile == "" ||
      this.ContactUs.Mobile == undefined ||
      this.ContactUs.Mobile == null
    ) {
      handleError("Mobile", "Field is Mandatory");
    } else if (this.ContactUs.Mobile.length < 10) {
      handleError("Mobile", "Mobile Number should be 10 digits");
    } else if (
      this.ContactUs.Message == "" ||
      this.ContactUs.Message == undefined ||
      this.ContactUs.Message == null
    ) {
      handleError("Message", "Field is Mandatory");
    } else {
      this.Save();
    }
  }

  handleClear() {
    this.ContactUs.Name = "";
    this.ContactUs.Email = "";
    this.ContactUs.Mobile = "";
    this.ContactUs.Message = "";
  }

  Save() {
    debugger;
    var val = {
      Name: this.ContactUs.Name,
      Email: this.ContactUs.Email,
      Mobile: this.ContactUs.Mobile,
      Message: this.ContactUs.Message,
    };
    this.healthMasterService.postContact(val).subscribe((res: any) => {
      if (res == true) {
        let data = res;
        Swal.fire(
          "Thank you for providing your details. Our team will contact you soon.",
          "",
          "success"
        );
        this.handleClear();
      }
    });
  }

  AlphaOnly($event) {
    debugger;
    var pattern = /^[a-zA-Z ]*$/;
    const inputChar = $event.key;
    if (!pattern.test(inputChar)) {
      //event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  onlyNumbers($event: KeyboardEvent): boolean {
    const pattern = /^[0-9]$/;
    const inputChar = $event.key;

    // Allow special keys like Backspace, Arrow keys, Delete, Tab, etc.
    if (
      $event.key === "Backspace" ||
      $event.key === "ArrowLeft" ||
      $event.key === "ArrowRight" ||
      $event.key === "Tab" ||
      $event.key === "Delete"
    ) {
      return true;
    }

    // Check if the input character matches the numeric pattern
    if (!pattern.test(inputChar)) {
      $event.preventDefault();
      return false;
    }

    return true;
  }

  emailOnly($event: KeyboardEvent): boolean {
    // Allow alphanumeric characters, @, ., -, _, and special keys like Backspace, Arrow keys, Delete, etc.
    const pattern = /^[a-zA-Z0-9@._-]$/;
    const inputChar = $event.key;

    // Allow special keys like Backspace, Arrow keys, Delete, etc.
    if (
      $event.key === "Backspace" ||
      $event.key === "ArrowLeft" ||
      $event.key === "ArrowRight" ||
      $event.key === "Tab" ||
      $event.key === "Delete"
    ) {
      return true;
    }

    // Check if the input character matches the allowed email pattern
    if (!pattern.test(inputChar)) {
      $event.preventDefault();
      return false;
    }
    return true;
  }
}
