import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms'
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { HealthservicesService } from '../../../services/healthservices.service';
import { SumInsured,FamilyMembers,Childrens,PinCode } from '../../../common/Models/healthMaster.models'
import  {insuredMember,proposer}  from '../../../common/Models/premium.models'
import {BuildplanComponent} from '../buildplan/buildplan.component';
import {ErrorHandle} from '../../../common/models/ErrorHandle';
import Swal from 'sweetalert2';
import { GlobalService } from 'src/app/global.service';

import { DateFormat } from "../../../common/date-format";
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MotorService } from 'src/app/services/motor.service';
//import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
declare var $: any;   

@Component({
  selector: 'app-health-index',
  templateUrl: './health-index.component.html',
  styleUrls: ['./health-index.component.css']
})
export class HealthIndexComponent implements OnInit {
step1: boolean=true;
step2: boolean=false;
step3: boolean=false;
date:any=[];
  
   floaterDob:any ;
  serializedDate:any;
  proposarDetailsForm: FormGroup;
  floterDetailsForm: FormGroup;
  submitted = false;
  ischeck  =false;
  showCover=true;
  datePickerConfig: Partial<BsDatepickerConfig>;
  public check: boolean;
  public members: insuredMember[];
  public flotermember: insuredMember=new insuredMember();
  dropdownList = [];
  dropdownListSumInsured = [];
  dropdownListChild = [];
  selectedItems = [];
  selectedItemsMember=[];
  selectedItemsChild=[];
  dropdownSettingsChild = {};
  dropdownSettings: IDropdownSettings = {};
  productForm: FormGroup;
  memberName: any;
  policytype:string;
  isSpouse:boolean;
  isSelf:boolean;
  proposer:proposer=new proposer();
  pincodes:PinCode[]=[];
  pincode:PinCode;
  BaseCoverageList=[];
  KeyList=[];
  HighlightList=[];
  ErrorHandle:ErrorHandle;
  UserId:number;
  verifyotp: boolean = false;
  isMobileOTPVerified: boolean = false;
  showDataBenefits: boolean = false
  @ViewChild(BuildplanComponent,{static:false}) buildplan: BuildplanComponent;
  @Input() isShowCover:boolean;
  showdata:boolean=false;
  primarySampleComponent: BuildplanComponent;
  public member: any[];
  submitbutton1: Boolean = false;

  gender:string='male';
  captureOtp:boolean=true;
  step2Back:boolean=true;
  
  showOTPFields = false;
  otpSentTime: number = 0;
  showResendButton: boolean = false;
  countdown: number = 30;
  timerInterval: any;
  displayTime = "";

  constructor(private router: Router, private fb: FormBuilder,private globalService: GlobalService
    ,private motorService: MotorService ,private healthMasterService:HealthservicesService,private dateAdapter: DateAdapter<Date>) { 
      
    this.datePickerConfig = Object.assign({}, { containerClass: 'theme-blue' })    
   this.members=[];
    this.ischeck  =false;   
    //this.isSelf=true;
    this.date[0] ='';// new FormControl(new Date());
    this.floaterDob='';
    this.proposer=new proposer();
    this.proposer.CustomerName="";
    this.proposer.Mobile="";
    this.proposer.Email="";
    this.proposer.Pincode="";

    this.flotermember.sumInsured=null;
    this.flotermember.dateOfBirth="";
    this.flotermember.age=0;

    this.pincode=new PinCode();
    

   
    dateAdapter.setLocale("en-in"); // DD/MM/YYYY
this.showdata=false;
    }

  ngOnInit() {
    debugger
    this.globalService.lendingpage = true;
    if (this.isShowCover != undefined) 
      this.showCover = this.isShowCover;

    
    this.bindModels();

    if(sessionStorage.getItem('displayData') === 'Yes'){
      this.showDataBenefits = true;
    }
    
    
  
    $(document).ready(function(){  
      $('.js-btn-tooltip').tooltip();
      $('.js-btn-tooltip--custom').tooltip({
        customClass: 'tooltip-custom'
      });
      $('.js-btn-tooltip--custom-alt').tooltip({
        customClass: 'tooltip-custom-alt'
      });
      
      $('.js-btn-popover').popover();
      $('.js-btn-popover--custom').popover({
        customClass: 'popover-custom'
      });
      $('.js-btn-popover--custom-alt').popover({
        customClass: 'popover-custom-alt'
      });
      // $("#datepicker").datepicker({
      //   changeMonth: true,
      //   changeYear: true
      // });
      sessionStorage.setItem('UserId','90');
    });
   
  

    this.check = false;
    //this.isSelf=true;
    $("#induinfo").hover(function () {
      $(".tooltopsindu").toggle();
    });

    $("#familyinfo").click(function () {
      $(".tooltopsfamily").toggle();
    });
    $(".closeTooltip").click(function () {
      $(".tooltops").hide("");
    });
    $("#pincodeinfo").click(function () {
      $(".tooltoppin").toggle();
    });

    $(".closeTooltip").click(function () {
      $(".tooltoppin").hide("");
    });

  
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll:false
    };
    this.dropdownSettingsChild = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      itemsShowLimit: 1,
      allowSearchFilter: true,
      enableCheckAll:false
    };
    this.fillMasterData();

    let objStep1=JSON.parse(sessionStorage.getItem('saveSessionStep1UserData'));
    if(objStep1){
      this.proposer.CustomerName=objStep1.CustomerName;
      this.proposer.Mobile=objStep1.Mobile;
      this.proposer.Email=objStep1.Email;
      this.gender=objStep1.gender;
      this.captureOtp=false;
      this.verifyotp=false;
      this.submitbutton1 = true;
    }
    let editMemberBack = sessionStorage.getItem('editMember');
    if(editMemberBack && editMemberBack == 'true'){
      this.step2Back=false;
    }

    let valStep2=sessionStorage.getItem('step2');
    if(valStep2 && valStep2 == 'true'){
      this.getSessionStep1UserData();
    }
    let valStep3=sessionStorage.getItem('step3');
    if(valStep3 && valStep3 == 'true'){
      this.getSessionStep2UserData();
    }
  }

  gotoFirstStep(){
    this.step1=true;
    this.step2=false;
    this.step3=false; 

    sessionStorage.setItem('step2','false');
  }

  saveSessionStep1UserData(){
    let obj={
      CustomerName:this.proposer.CustomerName,
      Mobile:this.proposer.Mobile,
      Email:this.proposer.Email,
      gender:this.gender
    }

    this.captureOtp=false;
    this.verifyotp=false;
    this.submitbutton1 = true;

    sessionStorage.setItem('saveSessionStep1UserData',JSON.stringify(obj))
    sessionStorage.setItem('step2',"true");
  }

  getSessionStep1UserData(){
    let obj=JSON.parse(sessionStorage.getItem('saveSessionStep1UserData'));
    if(obj){
      this.proposer.CustomerName=obj.CustomerName;
      this.proposer.Mobile=obj.Mobile;
      this.proposer.Email=obj.Email;
      this.gender=obj.gender;

      this.step1 = false;
      this.step2 = true;
      this.step3 = false;
    }
  }

  saveSessionStep2UserData(){
    let obj={
      policytype:this.policytype,
      Pincode:this.proposer.Pincode
    }

    sessionStorage.setItem('saveSessionStep2UserData',JSON.stringify(obj))
    sessionStorage.setItem('step3',"true");
  }
  getSessionStep2UserData(){
    let obj=JSON.parse(sessionStorage.getItem('saveSessionStep2UserData'));
    if(obj){
      this.policytype=obj.policytype;
      this.proposer.Pincode=obj.Pincode;

      this.step1=false;
      this.step2=false;
      this.step3=true; 
    }
  }

  gotoSecondStep() {
    
    if (document.querySelector('input[name="radio"]:checked') == null) {
      Swal.fire('Please select gender', '', 'error');
      document.getElementById("male").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("male").style.border = "1px solid #A5A5A5";
    }
    if(this.proposer.CustomerName==""){
      Swal.fire('Please enter Name', '', 'error');
      document.getElementById("male").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("male").style.border = "1px solid #A5A5A5";
    }
    if(this.proposer.Mobile==""){
      Swal.fire('Please enter Mobile', '', 'error');
      document.getElementById("mobile").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("mobile").style.border = "1px solid #A5A5A5";
    }
    if(this.proposer.Email==""){
      Swal.fire('Please enter Email', '', 'error');
      document.getElementById("email").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("email").style.border = "1px solid #A5A5A5";
    }
       // Email Validation
      //  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const emailPattern = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
       if(!emailPattern.test(this.proposer.Email)){
         Swal.fire('Please Enter Email In Valid Format', '', 'error');
         return false;
       }

       const phoneno = /^[6-9]\d{9}$/gm;
       if(!phoneno.test(this.proposer.Mobile)){
        Swal.fire('Please Enter Mobile No In Valid Format', '', 'error');
        return false;
      }
    // if(this.isMobileOTPVerified != true){
    //       Swal.fire("", "Kindly Verify Mobile Number", "error");
    //       return;
    // }
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
    this.saveSessionStep1UserData();
  }

  isValidPinCode(str) {
    // Regex to check valid
    // Pincode of India
    let regex = new RegExp(/^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/);
 
    // if str
    // is empty return false
    if (str == null) {
        return "false";
    }
 
    // Return true if the str
    // matched the ReGex
    if (regex.test(str) == true) {
        return "true";
    }
    else {
        return "false";
    }
}
gotoSecondStep1(){
  this.step1 = false;
  this.step2 = true;
  this.step3 = false;
  sessionStorage.setItem('step3','false');
}
  
  gotoThirdStep(){

    if(!this.policytype){
      Swal.fire('Select Insure', '', 'error');
      return false;
    }

    if (document.querySelector('input[name="profile"]:checked') == null) {
      // Swal.fire('Please select Policy Type', '', 'error');
      document.getElementById("individual").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("individual").style.border = "1px solid #A5A5A5";
    }
if(this.proposer.Pincode==""){
      Swal.fire('Please enter Pincode', '', 'error');
      document.getElementById("txtPincode").style.border = "1px solid red";
      return false;
    }
    else {
      document.getElementById("txtPincode").style.border = "1px solid #A5A5A5";
    }

     // Pincode Validation
     if(this.isValidPinCode(this.proposer.Pincode) === "false"){
      Swal.fire('Please Enter Pincode In Valid Format', '', 'error');
      return false;
    }

    this.step1=false;
    this.step2=false;
    this.step3=true; 
    this.saveSessionStep2UserData();
   }

   fillMasterData(){
    this.healthMasterService.getFamilyMembeers().subscribe((res: FamilyMembers[]) => {    
     let tmp = [];
      if (res != null) {
        for (let i = 0; i < res.length; i++) {
          tmp.push({ item_id: res[i].MemberId, item_text:res[i].MemberName });        
        }
        this.dropdownList=tmp;   
        
      }
    });

    this.healthMasterService.getChilds().subscribe((res: Childrens[]) => {    
      let tmp = [];
       if (res != null) {
         for (let i = 0; i < res.length; i++) {
           tmp.push({ item_id: res[i].ChildId, item_text:res[i].Name });        
         }
          this.dropdownListChild =tmp;    
 
       }
     });
    
     this.healthMasterService.getSumInsured().subscribe((res: SumInsured[]) => {    
      let tmp = [];
       if (res != null) {
         for (let i = 0; i < res.length; i++) {
           tmp.push({ item_id: Number(res[i].SumInsuredValue)/100000, item_text:res[i].SumInsuredText });        
         }
         console.log(tmp);
          this.dropdownListSumInsured =tmp;    
 
       }
     });
     
  }

  bindModels():void{    
   
    if( sessionStorage.getItem('CoverType') !=null){
      this.policytype= sessionStorage.getItem('CoverType') ;
      this.CoverType(this.policytype);
      if(sessionStorage.getItem('members')!=null){
        this.members=JSON.parse(sessionStorage.getItem('members'));
        this.members=this.filterMembers();
        let bindChlidDrop=[];
        let bindOtherMemberDrop=[];
        for(let i=0;i<this.members.length;i++){
          if(this.members[i].memberName=="Self")
            this.isSelf=true;
            if(this.members[i].memberName=="Spouse")
            this.isSpouse=true;
            if(this.members[i].memberName.slice(0, 5) =='Child'){
              bindChlidDrop.push({item_id:this.members[i].id,item_text:this.members[i].memberName});              
            }
            if(this.members[i].memberName.slice(0, 5) !='Child' && this.members[i].memberName !="Self" && this.members[i].memberName!="Spouse"){
              bindOtherMemberDrop.push({item_id:this.members[i].id,item_text:this.members[i].memberName});              
            }
           
              this. date[this.members[i].id] = new FormControl(new Date(this.members[i].dateOfBirth));
        }
        this.selectedItemsChild=bindChlidDrop; //||JSON;
        this.selectedItemsMember=bindOtherMemberDrop;
        if(this.policytype=="Individual"){        
                  
        }
         else{
          this.flotermember=JSON.parse(sessionStorage.getItem('members'))[0];
          this.floaterDob=new FormControl(new Date(this.flotermember.dateOfBirth));
          //this.isSelf=true;
         }
      }
    }
    if( !( sessionStorage.getItem('customer')==null || sessionStorage.getItem('customer')==''))
       this.proposer  =JSON.parse(sessionStorage.getItem('customer'));   
       this.getCityByPincode(this.proposer.Pincode )  ; 
   

  }
  quantities(): FormArray {
    return this.productForm.get("quantities") as FormArray
  }
  newQuantity(memberName: any): FormGroup {
    return this.fb.group({
      name: '',
      age: '',
      memberName: memberName,
    })
  }
  addQuantity(memberId: any, memberName: any) {
    this.quantities().push(this.newQuantity(memberName));
    //this.member=this.quantities

    this.member.push({ id: memberId, name: memberName });

  }
  removeQuantity(i: number) {
    this.quantities().removeAt(i);
  }

  onItemSelectChild(item: any) {
    if (item.item_id != 0) {
      this.addMember(item.item_id, item.item_text);
    }

  }
  onDeSelectChild(item: any) {
    for (var i = 0; i < this.members.length; i++) {
      if (this.members[i].id == item.item_id) {
        if(this.members[i].memberName== item.item_text){
          this.removeMember(i);
        }       
      
      }
    }
  }
 

  onItemSelect(item: any) {
    $('#divFamilyMember').show();
    if (item.item_id != 0) {
      this.addMember(item.item_id, item.item_text);
    }
  }
  onDeSelect(item: any) {    
    for (var i = 0; i < this.members.length; i++) {
      if (this.members[i].id == item.item_id) {
        if(this.members[i].memberName== item.item_text){
       this.removeMember(i);
      }
      }
    }
  }
 
  RedirectToBuildPlan(): void {debugger;
    let validate=true;
    let floterValidate=true;
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/; 
    var regexMobile = /^[6-9]\d{9}$/gm;
    // if(this.proposer.CustomerName==""){
    //   document.getElementById("customername").style.border = "1px solid red";
    //   validate=false;
    // }
    // else if(this.proposer.CustomerName !=""){
    //   document.getElementById("customername").style.border = "1px solid #A5A5A5";
    //   validate=true;
    // }

    // if(this.proposer.Mobile==""){
    //   document.getElementById("mobile").style.border = "1px solid red";
    //   validate=false;
    // }
    // else if(this.proposer.Mobile !=""){
    //   document.getElementById("mobile").style.border = "1px solid #A5A5A5";
    //   validate=true;
    // }

    // if(this.proposer.Mobile ==""){
    //   document.getElementById("mobile").style.border = "1px solid red";
    //   validate=false;
    // }
    // else if(this.proposer.Mobile !=""){
    //   if(!regexMobile.test(this.proposer.Mobile)){
    //     document.getElementById("mobile").style.border = "1px solid red";
    //     return;
    //   }else{
    //     document.getElementById("mobile").style.border = "1px solid #A5A5A5";
    //     validate=true;
    //   }      
    // }

    // if(this.proposer.Email ==""){
    //   document.getElementById("email").style.border = "1px solid red";
    //   validate=false;
    // }
    // else if(this.proposer.Email !=""){
    //   if(!regex.test(this.proposer.Email)){
    //     document.getElementById("email").style.border = "1px solid red";
    //     return;
    //   }else{
    //     document.getElementById("email").style.border = "1px solid #A5A5A5";
    //     validate=true;
    //   }      
    // }
    
    // if(this.pincode.PinCode==null || this.pincode.PinCode==""){
    //   document.getElementById("pincode").style.border = "1px solid red";
    //   validate=false;
    // }
    // else if(this.pincode.PinCode.length < 6 ){debugger;
    //   document.getElementById("pincode").style.border = "1px solid #A5A5A5";
    //   validate=false;
    // }
    // else if(this.pincode.PinCode !="" && this.pincode.PinCode.length == 6 ){
    //   document.getElementById("pincode").style.border = "1px solid #A5A5A5";
    //   validate=true;
    // }

    if(this.policytype==null){
      validate=false;
      Swal.fire('Please policy type', '', 'error');   
    }

    if(this.members.length==0){
      validate=false;
      Swal.fire('Please select member', '', 'error');   
    }
    
   
  if (validate==true) {
    if(this.policytype == 'FamilyFloater'){      
      if(this.flotermember.sumInsured==null){
        document.getElementById("floaterSumDropdown").style.border = "1px solid red";
        floterValidate=false;
      }
      else if(this.flotermember.sumInsured !=null){
        document.getElementById("floaterSumDropdown").style.border = "1px solid #A5A5A5";
        floterValidate=true;
      }

      if(this.flotermember.dateOfBirth==null || this.flotermember.dateOfBirth==""){
        document.getElementById("flotermember_Dob").style.border = "1px solid red";
        floterValidate=false;
      }
      else if(this.flotermember.dateOfBirth !=""){
        document.getElementById("flotermember_Dob").style.border = "1px solid #A5A5A5";
        floterValidate=true;
      }
      if(this.flotermember.age < 18){
        Swal.fire('Elder member age minimum 18 years', '', 'error');      
        return;
      }
      if(this.flotermember.age > 100){
        Swal.fire('Age should not be more than 100 years', '', 'error');      
        return;
      }  
    }

   


    if(this.policytype == 'Individual'){
      for(let i=0;i<this.members.length;i++){
        let m=this.members[i];
        // let MemAge=this.returnAge(m.dateOfBirth);
        let memDOB=new Date(m.dateOfBirth);
        let timeDiff = Math.abs(Date.now() - memDOB.getTime());
        let MemAge = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);
        
        if(m.age <18 && m.memberName.slice(0, 5) !='Child'){
          Swal.fire('Member age minimum 18 years', '', 'error');      
          return;
        }
        if(MemAge >100){
          Swal.fire('Age should not be more than 100 years', '', 'error');      
          return;
        }
        if(MemAge==100){
          
          var todayDate=new Date();
        var ageyear = todayDate.getFullYear() - memDOB.getFullYear();
        var agemonth = todayDate.getMonth() - memDOB.getMonth();
        var ageday = todayDate.getDate() - memDOB.getDate();
        let nowDay = new Date();
        if(ageyear>100){  
          Swal.fire('Age should not be more than 100 years', '', 'error');
          return;
            }
          if(ageday>0){  
        Swal.fire('Age should not be more than 100 years', '', 'error');
        return;
          }
          if(agemonth>0){ 
            Swal.fire('Age should not be more than 100 years', '', 'error');
            return;
          }
        }
        if(m.age <1){
          if(m.memberName.slice(0, 5) =='Child'){
            let day=this.datetodays(m.dateOfBirth);
            if(day<91){            
              Swal.fire('Child age minimum 91 day', '', 'error');
              return;
            }
          }
        }

        if(m.sumInsured==""){
          document.getElementById("sumInsured_"+m.memberName).style.border = "1px solid red";
          floterValidate=false;
        }
        else if(m.sumInsured !=""){
          document.getElementById("sumInsured_"+m.memberName).style.border = "1px solid #A5A5A5";
          floterValidate=true;
        }
  
        if(m.dateOfBirth==null || m.dateOfBirth == ""){
          document.getElementById("dateOfBirth_"+m.memberName).style.border = "1px solid red";
          floterValidate=false;
        }
        else if(m.dateOfBirth !=""){
          document.getElementById("dateOfBirth_"+m.memberName).style.border = "1px solid #A5A5A5";
          floterValidate=true;
        }
      }      
  }else{
      if(this.members.length<2){
        debugger
        floterValidate=false;       
        Swal.fire('Select more than one member', '', 'error');
      }

  }
  
  if(floterValidate==true){
        if (sessionStorage.getItem('CoverType') == 'Individual') {
          sessionStorage.setItem('members',JSON.stringify(this.members) );
        }
        else{
          if(this.flotermember.memberName==null)this.flotermember.memberName='Elder';
          for(let i=0;i<this.members.length;i++){
            
            if(this.members[i].memberName=='Elder')
            this.removeMember(i);         
          }
        this.members.unshift(this.flotermember);        
        }
        ///this.members=JSON.parse(localStorage.getItem('members'));
        let membersdata=[];
  
        for(let i=0;i<this.members.length;i++){
          let m=this.members[i];
         // if (localStorage.getItem('CoverType') != 'Individual'){
            if(m.memberName!='Elder' && sessionStorage.getItem('CoverType') != 'Individual'){             
              var currentdate = new Date(); 
              m.dateOfBirth=currentdate.getFullYear()+'-'+(currentdate.getMonth()+1)+'-'+currentdate.getDate();
              m.age=0;
            }else{
              var dob=new Date(m.dateOfBirth);
              m.dateOfBirth=dob.getFullYear()+'-'+(dob.getMonth()+1)+'-'+dob.getDate();
            }
          //}
                   
          let sumInsures=parseFloat(m.sumInsured)*100000; 
          if(m.dateOfBirth==null){
            membersdata.push({Relation:m.memberName,SumInsured:sumInsures,DateOfBirth:this.members[0].dateOfBirth,Age:m.age,
              weight:0,height:0,bmi:0});
          }
          else{
            membersdata.push({Relation:m.memberName,SumInsured:sumInsures,DateOfBirth:m.dateOfBirth,Age:m.age,
              weight:0,height:0,bmi:0});
          }
          
          
        }
     
      let quoteId=0;
      if(sessionStorage.getItem('quotaionId')!=null)
      quoteId=parseInt( sessionStorage.getItem('quotaionId'));
     let userId= sessionStorage.getItem('UserId')
      let policyType=sessionStorage.getItem('CoverType');
      let quoteData={
        QuoteId:quoteId,
        HealthQuoteRequest:{
          SessionId:'',
          IPAddress:'',
          UserId:userId,
          ReqDate:new Date(),
          NoOfMembers:this.members.length,
          PolicyType:policyType,
          Portal:'B2C'
        },
        HealthQuoteMembers:membersdata.filter((v,i,a)=>a.findIndex(t=>(t.Relation === v.Relation))===i),
        BajajPolicyCoverData : {
          PanIndiaCover:'',
          RentCappingDiscount:'',
          WaiverRent:'',
          NonMedical:'',
          HPR:'',
          HPRValue:''
        }      
      } 
      

      this.healthMasterService.postQuoteData(quoteData).subscribe((res:number)=>{   
        let data=res;
        sessionStorage.setItem('quotaionId',data.toString());
        this.proposer.Lob='HEALTH';
        this.proposer.QuotationId=data;
        this.healthMasterService.postCustomer(this.proposer).subscribe((res:any)=>{   
          let data=res;
          if( this.proposer!=null)          
            sessionStorage.setItem('customer',JSON.stringify( this.proposer));
            this.submitted= true;
            sessionStorage.setItem('members',JSON.stringify(this.members) );
            if(this.showCover==true){
              // this.router.navigateByUrl('/buildplan');
              this.router.navigateByUrl('/healthquotation');
            }
          //if(data===true) alert('saved');
        });
        if(this.showCover){
          this.ischeck=true;
         // this.buildplan.getMembers();
        }
      });  
    }
  }
}

  RedirectToHealth(): void {
    this.ischeck=false;
    //this.router.navigateByUrl('/buildplan');
  }
  CoverType(type: string) {
   // alert(this.showCover);
    if(this.showCover==false) return false;

    if (sessionStorage.getItem('CoverType') == null) {
      sessionStorage.setItem('CoverType', type);
      this.policytype=type;
      if (sessionStorage.getItem('CoverType') == 'Individual') {
        $('#divFamilyFloater').hide();
        $('#divIndividual').show();

      } else {
        $('#divIndividual').hide();
        $('#divFamilyFloater').show();
        this.floaterDob='';
      }
    }
    else {
      sessionStorage.setItem('CoverType', type);
      if (sessionStorage.getItem('CoverType') == 'Individual') {        
        this.selectedItemsMember=[];
        this.selectedItemsChild=[];
        this.isSpouse = false;
        this.isSelf = true;
      
      this.members=[];
      this.addMember(1,'Self');
       $('#divFamilyFloater').hide();
        $('#divIndividual').show();

      } else {
        this.selectedItemsMember=[];
        this.selectedItemsChild=[];
        this.isSpouse = false;
        this.members=[];
        this.addMember(1,'Self');
        
        $('#divIndividual').hide();
        $('#divFamilyFloater').show();

      }
    }
  }


  addMember(id: number, member: string) {   
    for(let i=0;i<this.members.length;i++){
      if(this.members[i].memberName===member)
         return;
    }
    let insmember=new insuredMember();
    insmember.id=id;
    insmember.age=0;  
    this.date[id] =''; 
    insmember.dateOfBirth=null;//yyyy+'-'+mm+'-'+dd;;
    // new FormControl(new Date());
    insmember.sumInsured='5';
    insmember.memberName=member;
    this.members.push(insmember);
  }
  formControlValueChanged() {  
    const phoneControl = this.proposarDetailsForm.get('phonenumber');  
    if (sessionStorage.getItem('CoverType') != 'Individual') {
      phoneControl.setValidators([Validators.required]);
    }
    else if (sessionStorage.getItem('CoverType') === 'Individual') {
      phoneControl.clearValidators();
    }
    phoneControl.updateValueAndValidity();
    
}

  removeMember(i: number) {
    this.members.splice(i, 1);
  }

  logValue() {
    console.log(this.members);
  }
  handleSelf($event) {
    if ($event.target.checked === true) {
      this.addMember(1,'Self');
      
    } else {      
      for (var i = 0; i < this.members.length; i++) {
        if (this.members[i].id == 1) {
          this.removeMember(i);
         
        }
      }
    }
  }
  handleSpouse($event) {
    if ($event.target.checked === true) {
      this.addMember(2,'Spouse');
     
    } else {
      for (var i = 0; i < this.members.length; i++) {
        if (this.members[i].id == 2) {
          this.removeMember(i);        
        }
      }
    }
  }

  calculateFloaterAge($event,member){
    let age=this.returnAge($event);
    if(age<18){
      Swal.fire('Elder member age minimum 18 years', '', 'error');
    }

    if($event==null)return;
    this.flotermember.dateOfBirth=$event.value;
    this.flotermember.age=age;
  }

  calculateAge($event,member,memberName){
    debugger
    let age=0;
    let day=0;
  if($event==null)return;
  if(memberName.slice(0, 5)=='Child'){
    day=this.returnDays($event);
    if(day<91){
      day=0;
      Swal.fire('Child age minimum 91 day', '', 'error');
    }else{
      age=this.returnAge($event);
    }
  }else{
    age=this.returnAge($event);
    if(age<18){
      Swal.fire('Member age minimum 18 years', '', 'error');
    }
  }

  if(age >100){
    Swal.fire('Age should not be more than 100 years', '', 'error');
  }

  if(age==100){
    
    var todayDate=new Date();
  var ageyear = todayDate.getFullYear() - $event.value.getFullYear();
  var agemonth = todayDate.getMonth() - $event.value.getMonth();
  var ageday = todayDate.getDate() - $event.value.getDate();
  let nowDay = new Date();
  if(ageyear>100){  
    Swal.fire('Age should not be more than 100 years', '', 'error');
      }
    if(ageday>0){  
  Swal.fire('Age should not be more than 100 years', '', 'error');
    }
    if(agemonth>0){ 
      Swal.fire('Age should not be more than 100 years', '', 'error');
    }

  }

  let Insmembers=this.filterMembers()
  for (var i = 0; i < Insmembers.length; i++) {
    if(member==i){
      Insmembers[i].age = age;
      Insmembers[i].dateOfBirth=$event.value;
    }
  }

  }

  onChangeSumInsured($event,mem){
   
    mem.sumInsured=$event.target.value;
  }
  private datetodays(date){
    let date1 = new Date(date); 
    let date2 = new Date(); 
    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();    
    // To calculate the no. of days between two dates
    var Diff_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Number(Diff_Days); 
  }
  private returnDays($event){
    if($event!=null){
    let day=$event.value.getDate();//$event.getDate();
    let m=$event.value.getMonth()
    let y=$event.value.getFullYear();
    let date1=new Date(y,m,day);
    //var date1 = new Date("06/30/2019");
    let date2 = new Date(); 

    // To calculate the time difference of two dates
    var Difference_In_Time = date2.getTime() - date1.getTime();    
    // To calculate the no. of days between two dates
    var Diff_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Number(Diff_Days); 
  }
}
  private returnAge($event){
    debugger;
    if($event!=null){
    let day=$event.value.getDate();
    let m=$event.value.getMonth()
    let y=$event.value.getFullYear();
    let dt=new Date(y,m,day);
    
    var ageDifMs = Date.now() - dt.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    let age= Math.abs(ageDate.getUTCFullYear() - 1970);
    return age;
  }
  }

  getPincode($event){
    let pin=$event.target.value;
    this.getCityByPincode(pin);
  }

  getCityByPincode(pin){debugger;
    this.healthMasterService.getPincodes(pin).subscribe((res:PinCode[])=>{
      this.pincodes=res;
      if(res.length>0)
      this.pincode=res[0];
    })
  }
  filterMembers(){
    if(this.members !=null){
      return this.members.filter(x => x.memberName != 'Elder');
    }
    
  }
  keyPress($event) {
    const pattern = /[0-9]/;    
    const inputChar = $event.key;
    if (!pattern.test(inputChar.toString())) {    
        // invalid character, prevent input
        event.preventDefault();
    }
}
onBlurMethod(val){
  document.getElementById("mobile").style.border = "1px solid #A5A5A5";
  const regex = /^[6-9]\d{9}$/gm;
  const str = val;
  let m;
  if(m = regex.exec(str) == null){

    document.getElementById("mobile").style.border = "1px solid red";
    
  }else{
      while ((m = regex.exec(str)) !== null) {        
          if (m.index === regex.lastIndex) {
              regex.lastIndex++;
          }
          m.forEach((match, groupIndex) => {
              //alert(`Found match, group ${groupIndex}: ${match}`);              
              
          });
      }
  }  
 }

alphaOnly($event) {
  var pattern = /^[a-zA-Z\s]*$/;///^[A-Za-z]+$/;
  const inputChar = $event.key;
  if(!pattern.test(inputChar))
  {
    event.preventDefault();
  }
}
updateCalcs(event){
  alert(event.value);
}
openNewpopup() {
  $("#Newpopup").modal('show');
  // $(".modal-backdrop").removeClass('modal-backdrop fade show');
}
openNewpopup1() {
  $("#Newpopup1").modal('show');
  // $(".modal-backdrop").removeClass('modal-backdrop fade show');
}
openNewpopup2() {
  $("#Newpopup2").modal('show');
  // $(".modal-backdrop").removeClass('modal-backdrop fade show');
}

SaveRiskcareUser(){
  debugger;
  this.healthMasterService.SaveRiskcareUser($("#email").val(),$("#mobile").val()).subscribe((res:any)=>{   
    if(res==true){
      let data=res;
      // Swal.fire('Thank you for providing your details. Our team will contact you soon.', '', 'success');
    }
    
  });
}

captureOTP(OTPType: any) {
  debugger;
  // this.submitted = true;

  // if (OTPType == 'M' && ($("#Email").val() == '' || $("#Email").val() == undefined)) {
  //   Swal.fire("", "Kindly Enter Emailid", "error");
  //   return;
  // }
  const phoneno = /^[6-9]\d{9}$/gm;
  if(!phoneno.test($("#mobile").val())){
   Swal.fire('Please Enter Mobile No In Valid Format', '', 'error');
   return false;
 }

  if (OTPType == 'H' && ( $("#mobile").val() == "" || $("#mobile").val() == null || $("#mobile").val() == undefined)) {
    Swal.fire("", "Please Enter Valid Mobile Number", "error");
    return;
  } else if (OTPType == "H" && $("#mobile").val().length < 10) {
    Swal.fire("", "Mobile Number should be 10 digits", "error");
    return;
  } 
  // this.verifyotp=true;

  var val = {
    IsActive: true,
    // Name: $("#Name").val(),
    UserId: $("#mobile").val(),
    MobileNo: $("#mobile").val(),
    OTPExpiry: "",
    EmailId: $("#Email").val(),
    Remarks: OTPType,
    Status: "",
    CreatedBy: "",
    CreatedByIP: ""
  }

  // this.spinner.show();

  this.motorService.SendOTP(val).subscribe((data: any) => {
    debugger;
    if (data.Status == "SUCCESS") {
      if (OTPType == 'H')
        Swal.fire("", "OTP sent to mobile number", "success");

      else
        Swal.fire("", "OTP sent to Email id", "success");

      this.verifyotp = true;
      this.otpSentTime = Date.now();
      this.startCountdown();
    }
    else {
      if (data.ErrorMessage != "") {
        Swal.fire("", data.ErrorMessage, "error");
        this.verifyotp = false;
      }
      else {
        Swal.fire("", "OTP sending failed", "error");    
         this.verifyotp = false;

      }
      // this.MobileNumber = '';
    }

  });
}

startCountdown() {
  this.countdown = 60;
  this.showResendButton = false;
  this.timerInterval = setInterval(() => {
    this.countdown--;

    if (this.countdown <= 0) {
      clearInterval(this.timerInterval);
      this.showResendButton = true;
      this.countdown = 0;
    }
    const minutes = Math.floor(this.countdown / 60);
    const seconds = this.countdown % 60;
    this.displayTime = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }, 1000);
}

ngOnDestroy() {
  if (this.timerInterval) {
    clearInterval(this.timerInterval);
  }
}

// OTPValidation(OTPType: any) {

//   // this.submitted = true;

//   if (OTPType == 'H' && ($("#mobile").val() == null  || $("#mobile").val() == undefined)) {
//     Swal.fire("", "Kindly Enter OTP sent on your Mobile number", "error");
//     return;
//   }

//   // if (OTPType == 'E' && (this.EmailOTP == '' || this.EmailOTP == undefined)) {
//   //   Swal.fire("", "Kindly Enter OTP sent on your Email id", "error");
//   //   return;
//   // }

//   var val = {
//     IsActive: true,
//     UserId: $("#mobile").val(),
//     MobileNo: $("#mobile").val(),
//     OTPExpiry: "",
//     EmailId: $("#Email").val(),
//     Remarks: "",
//     Status: "",
//     CreatedBy: "",
//     CreatedByIP: ""
//   }
//   this.motorService.OTPValidation(val).subscribe((data: any) => {
//     debugger;


//     if (OTPType == 'H' && ($("#MOTP").val() == data[0].OTP)) {
//       Swal.fire("", "OTP Verified", "success");
//       this.isMobileOTPVerified = true;
//       this.submitbutton1 = true;
//     }
//     // else if (OTPType == 'E' && (this.EmailOTP == data[0].EOTP)) {
//     //   //Swal.fire("", "Email OTP is correct", "success");
//     //   this.isEmailOTPVerified = true;
//     // }
//     else if (OTPType == 'H' && ($("#MOTP").val() != data[0].OTP)) {
//       Swal.fire("", "OTP is incorrect", "error");
//     }
//     // else if (OTPType == 'E' && (this.EmailOTP != data[0].EOTP)) {
//     //   Swal.fire("", "Email OTP is not correct", "error");
//     // }
//     else {
//       Swal.fire("", "Please provide correct OTP", "error");
//     }

//     //}
//   });

//   ////

// }

OTPValidation(OTPType: any) {
  debugger;
  if (OTPType === "H" && ($("#mobile").val() === "" || $("#mobile").val() === undefined)) {
      Swal.fire("", "Kindly Enter OTP sent on your Mobile number", "error");
      return;
  }
    var val = {
    IsActive: true,
    UserId: $("#mobile").val(),
    MobileNo: $("#mobile").val(),
    OTP: $("#MOTP").val(), 
    OTPExpiry: "",
    EmailId: $("#Email").val(),
    Remarks: "",
    Status: "",
    CreatedBy: "",
    CreatedByIP: ""
  }
  
  sessionStorage.setItem("MobileNo", $("#Mobile").val());

  this.motorService.OTPValidation(val).subscribe((data: any) => {
      debugger;

      if (data.success) {
          Swal.fire("", "OTP Verified", "success");
          this.isMobileOTPVerified = true;
          this.submitbutton1 = true;
          // this.Login();
      } else {
          Swal.fire("", data.message || "Please provide correct OTP", "error");
      }
  });
}

}
