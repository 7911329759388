import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpEvent, HttpParams } from "@angular/common/http";
import { AppSettings } from '../globals';
import { proposer } from '../common/Models/premium.models';
import { ContactUs } from '../common/Models/healthMaster.models';
import { Customer } from '../common/Models/healthMaster.models';
import { Renewal } from '../common/Models/healthMaster.models';
import { of, Observable } from 'rxjs';
import { User } from '../common/Models/userDetail';

@Injectable({
  providedIn: 'root'
})
export class HealthservicesService {

  // readonly APIUrl = "https://localhost:44303/api";
  urlCKYC = AppSettings.API_ENDPOINT_CKYC;

  // readonly imagePath = "https://localhost:44303/"
  // readonly APIUrl = "https://www.arkavach.com:8436/api";//PROD
  readonly APIUrl = "https://api.finqyinsure.com/api";//PROD


  // readonly imagePath = "https://www.arkavach.com:8436/";//PROD
  readonly imagePath = "https://api.finqyinsure.com/";//PROD

  url = AppSettings.API_ENDPOINTHEALTH;
  constructor(private http: HttpClient) { }
  getPincodes(pincode) {
    return this.http.get(this.url + '/Common/GetStateCityPincode?pin=' + pincode);
  }
  getSalutations(lob: number, companyId: number) {
    return this.http.get(this.url + '/Common/GetSalutation?LobId=' + lob + '&CompanyId=' + companyId);
  }
  getOccupations(lob: number, companyId: number) {
    return this.http.get(this.url + '/Common/GetOccupation?LobId=' + lob + '&CompanyId=' + companyId);
  }
  getNomineeRelations(lob: number, companyId: number) {
    return this.http.get(this.url + '/Common/GetNomineeRelation?LobId=' + lob + '&CompanyId=' + companyId);
  }
  getSumInsured() {
    return this.http.get(this.url + '/HealthMaster/GetSumInsured');
  }
  getFamilyMembeers() {
    return this.http.get(this.url + '/HealthMaster/GetFamilyMembers');
  }
  getChilds() {
    return this.http.get(this.url + '/HealthMaster/GetChilds');
  }
  postCustomer(data: proposer) {
    return this.http.post(this.url + '/Common/SaveProposerCustomer', data);
  }
  postContact(data: ContactUs) {
    // var ContactUs=JSON.stringify(data);
    return this.http.post(this.url + '/Common/SaveContactUs', data);
  }
  postHealthCustomer(data: Customer) {
    return this.http.post(this.url + '/Common/SaveCustomer', data);
  }
  postRenewalData(data: Renewal) {
    return this.http.post(this.url + '/Common/SaveRenewalData', data);
  }
  getBuildFeatures() {
    return this.http.get(this.url + '/Admin/GetFeatureType');
  }

  postQuoteData(data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    return this.http.post(this.url + '/HealthMaster/SaveHealthQuotationData', data, httpOptions);
  }

  postQuoteCoverFeatureData(data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    return this.http.post(this.url + '/HealthMaster/SaveHealthMemberCoverFeatureData', data, httpOptions);
  }
  getQuotationPlan(quoteId: number) {
    return this.http.get(this.url + '/HealthMaster/GetFeaturePlanData?QuotionId=' + quoteId);
  }
  // getQuotation(quoteId: number, planId: number, productId: number) {
  //   return this.http.get(this.url + '/HealthQuotation/HealthQuotesGateway?QuotionId=' + quoteId + '&PlanTypeID=' + planId + '&ProductId=' + productId);
  // }
  getQuotation(flags: number, quoteId: number, planId: number, productId: number) {
    return this.http.get(this.url + '/HealthQuotation/HealthQuotesGateway?flags=' + flags + '&QuotionId=' + quoteId + '&PlanTypeID=' + planId + '&ProductId=' + productId);
  }

  getQuoteRequestData(quoteId: number) {
    return this.http.get(this.url + '/HealthMaster/GetHealthQuotationRequestData?QuotionId=' + quoteId);
  }
  getRedirectCkycDetails(quoteId: number) {
    return this.http.get(this.url + '/HealthMaster/getRedirectCkycDetails?QuotionId=' + quoteId);
  }
  getHealthKeyFeatures(productId: number, planId: number) {
    return this.http.get(this.url + '/HealthMaster/GetHealthKeyFeatures?ProductId=' + productId + '&PlanTypeId=' + planId);
  }
  getCashHospitalsList(state, city, pincode, productId) {
    return this.http.get(this.url + '/HealthMaster/GetHospitalDetails?state=' + state + '&city=' + city + '&pincode=' + pincode + '&productId=' + productId);
  }
  getHealthDocumentsList(productId: number, planId: number) {
    let Type = 'Document';
    return this.http.get(this.url + '/HealthMaster/GetHealthDocuments?ProductId=' + productId + '&PlanTypeId=' + planId + '&Type=' + Type);
  }

  geUser(userId: string) {
    let headers_object = new HttpHeaders().append('Authorization', 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiYXNlZW0uZ29lbEByaXNrY2FyZS5jby5pbiIsImV4cCI6MTYyMTUwMTYxNywiaXNzIjoidGhyZWVuaW5lLmNvLnVrIiwiYXVkIjoiU2FtcGxlQXVkaWVuY2UifQ.OGwCLvkH7JmrfAiLhCryG3bgVkEZWv8n1Vh6oh-YbT4');
    return this.http.get(this.url + '/Authenticate/GetUser?user=' + userId, {
      headers: headers_object
    });
  }

  getCompanyInformationList(CompanyId: number) {
    return this.http.get(this.url + '/HealthMaster/GetCompanyInformationList?BusType=General&CompanyId=' + CompanyId);
  }




  public DownloadViewPlanFile(id: number): Observable<HttpEvent<Blob>> {
    // const reqHeader = new HttpHeaders
    //   ({ 'Content-Type': 'application/json', authorization: 'bearer ' + localStorage.getItem('access_token') });

    return this.http.request(new HttpRequest(
      'GET',
      `${this.url}/HealthMaster/DownloadViewPlanFile/?id=${id}`,
      null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  GetBaseCoverage(searchtext) {
    return this.http.get(this.url + '/Admin/GetBaseCoverage?ActivityType=' + searchtext);
  }

  GetHealthFeaturesList() {
    return this.http.get(this.url + '/Admin/GetFeatureType');
  }

  GetHealthKeyHighlights(companyId, productId, planId) {
    return this.http.get(this.url + '/HealthMaster/GetHealthKeyHighlights?CompanyId=' + companyId + '&ProductId=' + productId + '&PlanId=' + planId);
  }

  GetClaimProcess(productId: number, planId) {
    let Type = 'Claim Process';
    return this.http.get(this.url + '/HealthMaster/GetHealthDocuments?ProductId=' + productId + '&PlanTypeId=' + planId + '&Type=' + Type);
    //return this.http.get(this.url+'/HealthMaster/GetClaimProcessList?CompanyId='+companyId+'&ProductId='+productId);
  }
  GetQuotesList(Userid: string) {
    debugger
    return this.http.get(this.url + '/HealthMaster/GetQuotesList_CP?Userid=' + Userid);
}
  GetQuotesListMotor(Userid: string) {
    debugger
    return this.http.get(this.url + '/HealthMaster/GetQuotesListMotor_CP?Userid=' + Userid);
  }
  // GetQuotesList(Userid: number) {
  //   return this.http.get(this.url + '/HealthMaster/GetQuotesList?Userid=' + Userid);
  // }
  // GetQuotesListMotor(Userid: number) {
  //   return this.http.get(this.url + '/HealthMaster/GetQuotesListMotor?Userid=' + Userid);
  // }
  
  GetFeaturesByPlanType(planTypeId, quoteId) {
    debugger
    return this.http.get(this.url + '/HealthMaster/GetPlanByFeatures?plan=' + planTypeId + '&QuotionId=' + quoteId);
  }
  GetFavouriteList(Userid: number) {
    return this.http.get(this.url + '/HealthMaster/GetFavouriteList?Userid=' + Userid);
  }
  GetPolicies(Userid: string, policyNo: string, policyStartDate: any, policyEndDate: any) {
debugger
    let params = new HttpParams();
    params = params.set('Userid', Userid.toString());
    if (policyNo.length > 0)
      params = params.set('policyNumber', policyNo)
    if (policyStartDate.toString().length > 0)
      params = params.set('policyStartDate', this.formatDate(policyStartDate));
    if (policyEndDate.toString().length > 0)
      params = params.set('policyEndDate', this.formatDate(policyEndDate));
    debugger
    const httpOptions = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + sessionStorage.getItem('access_token')),
      params: params
    }

    return this.http.get(this.url + '/Policy/GetPolicies_CP', httpOptions);
  }

  GetMyBusiness(Userid: any, policyNo: string, policyStartDate: any, policyEndDate: any) {

    let params = new HttpParams();
    params = params.set('Userid', Userid.toString());
    if (policyNo.length > 0)
      params = params.set('policyNumber', policyNo)
    if (policyStartDate.toString().length > 0)
      params = params.set('policyStartDate', this.formatDate(policyStartDate));
    if (policyEndDate.toString().length > 0)
      params = params.set('policyEndDate', this.formatDate(policyEndDate));
    debugger
    const httpOptions = {
      headers: new HttpHeaders().append('Authorization', 'Bearer ' + sessionStorage.getItem('access_token')),
      params: params
    }

    return this.http.get(this.url + '/Policy/GetMyBusiness', httpOptions);
  }

  GetRenewalData(Userid: any, policyNo: string, policyStartDate: any, policyEndDate: any) {

    let params = {
      UsermailId: Userid.toString(),
      PolicyNumber: policyNo,
      FromDate: this.formatDate(policyStartDate),
      ToDate: this.formatDate(policyEndDate)
    };
    debugger
    // const httpOptions = {
    //   headers: new HttpHeaders().append('Authorization', 'Bearer ' + sessionStorage.getItem('access_token')),
    //   params: params
    // }
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }
    return this.http.post(this.url + '/Common/GetRenewalData', params, httpOptions);
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  CreateUserLogin(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/CreateUserLogin', val);
  }
  Select_UserLoginBy_Id(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/Select_UserLoginBy_Id', val);
  }
  CreateUserProfile(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/CreateUserProfile', val);
  }
  Select_UserProfile() {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/Select_UserProfile', '');
  }
  Select_UserProfileBy_Id(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/Select_UserProfileBy_Id', val);
  }
  UpdateUserProfile(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/UpdateUserProfile', val);
  }
  CreateUserPANDetails(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/CreateUserPANDetails', val);
  }
  CreateUserAadharDetails(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/CreateUserAadharDetails', val);
  }
  CreateUserBankDetail(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/CreateUserBankDetail', val);
  }
  CreateUserEducationalDetail(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/CreateUserEducationalQualification', val);
  }
  sendStatusMail(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/sendStatusMail', val);
  }
  // FetchStateCity(Pincode: any): Observable<any> {
  //   // return this.http.get<any[]>('https://api.postalpincode.in/pincode/' + Pincode);
  //   const httpOptions = {
  //     headers: new HttpHeaders().append('Access-Control-Allow-Origin','*')
  //     .append('content-type','application/json')
  //     .append('Access-Control-Allow-Headers','Content-Type')
  //     .append('Access-Control-Allow-Methods','GET,POST,OPTIONS,DELETE,PUT')
  //   } 
  //   return this.http.get<any>('http://1.22.180.122:4441/api/MasterEntry/PinCode/'+ Pincode + '/admin@riskcare.co.in',httpOptions);
  // }

  FetchStateCity(Pincode: any) {
    return this.http.get<any[]>('https://api.postalpincode.in/pincode/' + Pincode);
    // return this.http.get('http://integration.rcbsnet.in/api/MasterEntry/PinCode/'+ Pincode + '/admin@riskcare.co.in');
  }

  Select_UserBankDetailBy_Id(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/Select_UserBankDetailBy_Id', val);
  }
  CreateUserStatusHistory(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/CreateUserStatusHistory', val);
  }
  GenerateDashboard(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/GenerateDashboard', val);
  }
  SelectUserStatusById(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/Select_UserStatusBy_Id', val);
  }
  SelectUserProfileById(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/Select_UserProfileBy_Id', val);
  }

  ReSendOTP(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/ReSendOTP', val);
  }

  FetchStarCity(Pincode: any, productid: any): Observable<any[]> {
    return this.http.get<any[]>(this.url + '/HealthMaster/GetStarcityList?Pincode=' + Pincode + '&ProductId=' + productid);
  }

  FetchStarArea(Pincode: any, CityId: any, productid: any): Observable<any[]> {
    return this.http.get<any[]>(this.url + '/HealthMaster/GetStarareaList?Pincode=' + Pincode + '&ProductId=' + productid + '&cityid=' + CityId);
  }

  GetApilogDetailsList(UserId, QuoteId, Startdate, EndDate) {
    return this.http.get<any[]>(this.url + '/Admin/GetApilogDetail?UserId=' + UserId + '&&QuoteId=' + QuoteId + '&&Startdate=' + Startdate + '&&EndDate=' + EndDate);
  }

  GetErrorProductAPILogData(QuoteId) {
    return this.http.get<any[]>(this.url + '/Admin/getErrorProductAPILogData?QuoteId=' + QuoteId);
  }

  DownloadQuote(LogId, quoteType) {
    return this.http.get(this.url + '/Admin/DownloadAPILogQuote?LogId=' + LogId + '&&quoteType=' + quoteType, { responseType: 'text' });
  }

  PushPOSPInRCBS(val: any) {
    debugger;
    return this.http.post(this.APIUrl + '/UserLogin/PushPOSPInRCBS', val);
  }


  GetInspectionList(Userid: any) {
    debugger
    return this.http.get(AppSettings.DomainUrlMotorCarInspection + 'Inspection/GetInspectionData?Userid=' + Userid);
  }

  UploadVehicleMappingDetails(file, UserId, flag) {
    const reqHeader = new HttpHeaders
      ({ authorization: 'bearer ' + localStorage.getItem('access_token') });
    return this.http.post(`${this.url}/Admin/UploadVehicleMappingDetails?UserId=` + UserId + `&&flag=` + flag, file, { headers: reqHeader });
  }

  InitiateKYCSTAR(file, val: any, ProductId, SessionId) {
    const data = val;
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    return this.http.post(this.urlCKYC + '/InitiateKYC/InitiateKYCSH?ProductId=' + ProductId + '&sessionId=' + SessionId + '&initiateKYCReq=' + data, file).toPromise();
    // return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCICICI?ProductId='+ProductId+'&sessionId='+SessionId,data,httpOptions).toPromise();
  }

  getDocumnetType(companyId) {
    return this.http.get(this.urlCKYC + '/Master/GetCKYCDocument?CompanyId=' + companyId);
  }
  InitiateKYCFGI(val: any, ProductId, SessionId, PlanId) {
    const data = val;
    // const httpOptions = {
    //   headers: new HttpHeaders({'Content-Type': 'application/json'})    }    

    //return this.http.post('https://localhost:44301/api/InitiateKYC/InitiateKYCFGI?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, data).toPromise();
    return this.http.post(this.urlCKYC + '/InitiateKYC/InitiateKYCFGI?ProductId=' + ProductId + '&sessionId=' + SessionId + '&PlanId=' + PlanId, data).toPromise();
  }
  InitiateKYCRGI(val: any, ProductId, SessionId, PlanId) {
    const data = val;
    // const httpOptions = {
    //   headers: new HttpHeaders({'Content-Type': 'application/json'})    }    

    return this.http.post(this.urlCKYC + '/InitiateKYC/InitiateKYCRGI?ProductId=' + ProductId + '&sessionId=' + SessionId + '&PlanId=' + PlanId + '&LOB=HEALTH', data).toPromise();
    // return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCICICI?ProductId='+ProductId+'&sessionId='+SessionId,data,httpOptions).toPromise();
  }
  InitiateKYCCARE(val: any, ProductId, SessionId, PlanId) {
    const data = val;
    return this.http.post(this.urlCKYC + '/InitiateKYC/InitiateKYCCARE?ProductId=' + ProductId + '&sessionId=' + SessionId + '&PlanId=' + PlanId + '&LOB=HEALTH', data).toPromise();
    //return this.http.post('https://localhost:44301/api/InitiateKYC/InitiateKYCCARE?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId+'&LOB=HEALTH', data).toPromise();
  }
  // UploadCKYCDetails(val: any, ProductId, SessionId, PlanId) {
  //   //return this.http.post('https://localhost:44301/api/UploadDocuments/UploadDocumentBajaj?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, val);
  //   return this.http.post(this.urlCKYC + '/UploadDocuments/UploadDocumentCARE?ProductId=' + ProductId + '&sessionId=' + SessionId + '&PlanId=' + PlanId, val);
  // }
  UploadCKYCDetails(val: any, ProductId, SessionId, PlanId, quoteId) {
    //return this.http.post('https://localhost:44301/api/UploadDocuments/UploadDocumentCARE?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId+'&proposalNumber='+quoteId, val);
    return this.http.post(this.urlCKYC + '/UploadDocuments/UploadDocumentCARE?ProductId=' + ProductId + '&sessionId=' + SessionId + '&PlanId=' + PlanId + '&proposalNumber=' + quoteId, val);
  }
  // InitiateKYCHDFC(val:any,ProductId,SessionId,PlanId){    
  //   const data=val;
  //   //return this.http.post('https://localhost:44301/api/InitiateKYC/InitiateKYCUIIC?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, data).toPromise();
  //   return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCHDFC?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, data).toPromise();
  // }

  InitiateKYCHDFC(queryParams: string, data: any) {
    debugger;
    const url = `https://pre-pod.arkavach.com:7097/api/InitiateKYC/InitiateKYCHDFC?${queryParams}`;
    
    return this.http.post(url, data).toPromise();
  }

  //Customer
  SignUpCustomer(val: any) {
    debugger;
    return this.http.post(this.urlCKYC + '/Customer/signUpCustomer', val,{responseType: 'text'}).toPromise();
  }
  LoginCustomer(data: any) {
    debugger;
    return this.http.post(this.urlCKYC + '/Customer/LoginCustomer', data).toPromise();
  }
  GetCustomerDetails(data: any) {
    debugger;
    return this.http.post(this.urlCKYC + '/Customer/GetCustomerDetails', data).toPromise();
  }
  UpdateCustomer(data: any) {
    debugger;
    return this.http.post(this.urlCKYC + '/Customer/UpdateCustomer', data,{responseType: 'text'}).toPromise();
  }
  GetCustomerPolicyDetails(data: any) {
    debugger;
    return this.http.post(this.urlCKYC + '/Customer/GetCustomerPolicyDetails', data).toPromise();
  }

  ForgetPassword(data: any) {
    debugger;
    return this.http.post(this.urlCKYC + '/Customer/ForgetPasswordCustomer', data,{responseType: 'text'}).toPromise();
  }
  SaveKycDetails(val:any,ProductId,SessionId){    
    const data=val;
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})    }    
    
      return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCGODIGIT?ProductId='+ProductId+'&sessionId='+SessionId, data).toPromise();
      // return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCICICI?ProductId='+ProductId+'&sessionId='+SessionId,data,httpOptions).toPromise();
  }

  InitiateKYCSH(DocumentID,DocumentNumber,ProductId,SessionId){    
//return this.http.get('https://localhost:44301/api/InitiateKYC/InitiateKYCSH?productType='+ProductId+'&SessionId='+SessionId+'&DocumentID='+DocumentID+'&DocumentNumber='+DocumentNumber).toPromise();
return this.http.get(this.urlCKYC+'/InitiateKYC/InitiateKYCSH?productType='+ProductId+'&SessionId='+SessionId+'&DocumentID='+DocumentID+'&DocumentNumber='+DocumentNumber+'&LOB=HEALTH').toPromise();
}
  getIDDocumnetType(companyId){
    return this.http.get(this.urlCKYC+'/Master/GetStarIDDocument?CompanyId='+companyId);
    // return this.http.get(this.urlCKYC+'/Master/GetCKYCDocument?CompanyId='+companyId);
  }
  UploadDocumentSH(val: any,ProductId,SessionId) {
    //return this.http.post('https://localhost:44301/api/UploadDocuments/UploadDocumentSH?ProductId='+ProductId+'&sessionId='+SessionId, val);
    return this.http.post(this.urlCKYC+'/UploadDocuments/UploadDocumentSH?ProductId='+ProductId+'&sessionId='+SessionId, val);
  }
  AddharKYCCARE(val:any,ProductId,SessionId,PlanId){    
    const data=val;
    return this.http.post(this.urlCKYC+'/GETCKYCStatus/CareGetAddharKYC?productType='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId+'&LOB=HEALTH', data).toPromise();
    //return this.http.post('https://uat.bimaraja.com:6631/api/GETCKYCStatus/CareGetAddharKYC?productType='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId+'&LOB=HEALTH', data).toPromise();
  //return this.http.post('https://localhost:44301/api/GETCKYCStatus/CareGetAddharKYC?productType='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId+'&LOB=HEALTH', data).toPromise();
  //return this.http.post('https://localhost:44301/api/InitiateKYC/InitiateKYCCARE?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId+'&LOB=HEALTH', data).toPromise();
  }
  
SaveRiskcareUser(Email, mobile) {
    // var ContactUs=JSON.stringify(data);
    return this.http.post(
      this.url +
        "/Common/SaveRiskcareUser?email=" +
        Email +
        "&mobile=" +
        mobile,
      ""
    );
  }
  SendOTP(data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    return this.http.post(this.url + '/Common/SendOTP', data, httpOptions);
  }

  OTPValidation(data) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    }

    return this.http.post(this.url + '/Common/UserLoginWithOTP', data, httpOptions);
  }


  applyJob(data: any) {
    debugger;
    return this.http.post(this.url + "/Common/SubmitJobAplicationDetails", data, { responseType: "text" });
  }

  uploadJob(data: any) {
    debugger;
    return this.http.post(this.url + "/Common/SubmitJobDetails", data, {responseType: "text"});
  }

  fetchPositionJob() {
    debugger;
    return this.http.post(this.url + "/Common/SelectMasterList", "");
  }
  fetchPosition(data: any) {
    debugger;
    return this.http.post( this.url + "/Common/JobDetails?flag=" + data.flag, "");
  }

  fetchBlog(data: any) {
    debugger;
    return this.http.post(this.url + "/Common/SelectBlogList?flag=" + data.flag, "");
  }

  InitiateKYCKOTAK(val: any, ProductId, SessionId, PlanId) {
    debugger
    const data = val;
    return this.http.post(this.urlCKYC + '/InitiateKYC/InitiateKYCKotakHealth?ProductId=' + ProductId + '&sessionId=' + SessionId + '&PlanId=' + PlanId + '&LOB=HEALTH', data).toPromise();
    // return this.http.post('https://localhost:44301/api/InitiateKYC/InitiateKYCKotakHealth?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId+'&LOB=HEALTH', data).toPromise();
  }
  
  InitiateKYCBAJAJ(val:any,ProductId,SessionId){    
    const data=val;
  return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCBAJAJ?ProductId='+ProductId+'&sessionId='+SessionId, data).toPromise();
  //return this.http.post('https://localhost:44301/api/InitiateKYC/InitiateKYCCARE?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId+'&LOB=HEALTH', data).toPromise();
  }
  InitiateKYCNB(val:any,ProductId,SessionId,PlanId){    
    const data=val;
  return this.http.post(this.urlCKYC+'/InitiateKYC/InitiateKYCNB?productType='+ProductId+'&SessionId='+SessionId+'&PlanId='+PlanId+'&LOB=HEALTH', data).toPromise();
  //return this.http.post('https://localhost:44301/api/InitiateKYC/InitiateKYCCARE?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId+'&LOB=HEALTH', data).toPromise();
  }
  UploadCKYCBajajDetails(val: any,ProductId,SessionId,PlanId) {
    // return this.http.post('https://localhost:44301/api/UploadDocuments/UploadDocumentBajaj?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, val);
    return this.http.post(this.urlCKYC+'/UploadDocuments/UploadDocumentBajaj?ProductId='+ProductId+'&sessionId='+SessionId+'&PlanId='+PlanId, val);
   }
}
