import { Component, OnInit } from '@angular/core';
import { HealthservicesService } from '../../services/healthservices.service';
import { Customer } from '../../common/Models/healthMaster.models';
import Swal from 'sweetalert2';

declare var $: any; 
@Component({
  selector: 'app-personal-accident',
  templateUrl: './personal-accident.component.html',
  styleUrls: ['./personal-accident.component.css']
})
export class PersonalAccidentComponent implements OnInit {

  Customer:any= new Customer();
  constructor(private healthMasterService:HealthservicesService) { 
    this.Customer=new Customer();
    this.Customer.CustomerName="";
    this.Customer.Email="";
    this.Customer.Product="Personal Accident Insurance";
    this.Customer.Lob="HEALTH";
    this.Customer.Gender="M";
  }

  ngOnInit(): void {
  }
  openNewpopup() {
    $("#Newpopup").modal('show');
    // $(".modal-backdrop").removeClass('modal-backdrop fade show');
  }
  openNewpopup1() {
    $("#Newpopup1").modal('show');
    // $(".modal-backdrop").removeClass('modal-backdrop fade show');
  }
  openNewpopup2() {
    $("#Newpopup2").modal('show');
    // $(".modal-backdrop").removeClass('modal-backdrop fade show');
  }
  Save(){debugger;
    this.healthMasterService.postHealthCustomer(this.Customer).subscribe((res:any)=>{   
      if(res==true){
        let data=res;
        Swal.fire('Thank you for providing your details. Our team will contact you soon.', '', 'success');
      }
    });
  }
}
