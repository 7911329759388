import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute  } from '@angular/router';
import { HealthProposalService } from '../../../services/proposal.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-healthpaymentsuccess',
  templateUrl: './healthpaymentsuccess.component.html',
  styleUrls: ['./healthpaymentsuccess.component.css']
})
export class HealthpaymentsuccessComponent implements OnInit {

  constructor(private router:ActivatedRoute,private proposalService:HealthProposalService) { }
  policyNo;
  companyId;
  amount;
  policyUrl;
  transactionId;
  image;
  transactionDate;fullName;planType;proposalid;DownloadLink
  ngOnInit() {
    debugger;
    this.policyNo=this.router.snapshot.queryParamMap.get('proposalNo');
    this.amount=this.router.snapshot.queryParamMap.get('amount');
    this.policyUrl=this.router.snapshot.queryParamMap.get('policyUrl');
    this.proposalid=this.router.snapshot.queryParamMap.get('proposalid');
    sessionStorage.removeItem('CoverType')
    sessionStorage.removeItem('quotaionId')
    sessionStorage.removeItem('members')
    sessionStorage.removeItem('customer')

     this.proposalService.GetHealthPaymentDetails(this.proposalid).subscribe((res:any)=>{        
        this.transactionId=res.TransactionId;
        this.amount=res.Premium;
        this.image=res.CompanyLogo;
        this.transactionDate=res.TransactionDate;
        this.fullName=res.FullName; 
        this.planType=res.PlanName;
        this.DownloadLink=res.DownloadLink;
        this.companyId=res.CompanyId;
        if (this.companyId =='17'){
          this.policyNo = res.POSPolicyNo;
        }else if (this.companyId =='23') {
          this.policyNo = res.POSPolicyNo;
        }
        else {
          this.policyNo=this.router.snapshot.queryParamMap.get('proposalNo');
        }
      });
      //PlanName

      
  }

  onClick(DownloadLink:string){
    if(DownloadLink){
      window.open(DownloadLink, "_blank");
    }else{
      Swal.fire('No Url Found', 'Please try again later', 'error');
    }
  }


}
