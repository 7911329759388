import { Component, OnInit } from '@angular/core';
import { HealthservicesService } from '../services/healthservices.service';
import { Customer } from '../common/Models/healthMaster.models';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-commercial-vehicle-insurance',
  templateUrl: './commercial-vehicle-insurance.component.html',
  styleUrls: ['./commercial-vehicle-insurance.component.css']
})
export class CommercialVehicleInsuranceComponent implements OnInit {
  hide: any;
  $element: any;
  Customer:any= new Customer();
  constructor(private healthMasterService:HealthservicesService) { 
    this.Customer=new Customer();
    this.Customer.CustomerName="";
    this.Customer.Email="";
    this.Customer.Product="GCV";
    this.Customer.Lob="COMMERCIAL";
    this.Customer.Gender="M";
  }

  ngOnInit() {
    // this.$element.on('click.dismiss.bs.modal', '[data-dismiss="modal"]', $.proxy(this.hide, this))
  }
  // openNewpopup() {
  //   $("#Newpopup").modal('show');
  //   // $(".modal-backdrop").removeClass('modal-backdrop fade show');
  // }
  // openNewpopup1() {
  //   $("#Newpopup1").modal('show');
  //   // $(".modal-backdrop").removeClass('modal-backdrop fade show');
  // }
  // openNewpopup2() {
  //   $("#Newpopup2").modal('show');
  //   // $(".modal-backdrop").removeClass('modal-backdrop fade show');
  // }
  Save(){debugger;
    this.healthMasterService.postHealthCustomer(this.Customer).subscribe((res:any)=>{   
      if(res==true){
        let data=res;
        Swal.fire('Thank you for providing your details. Our team will contact you soon.', '', 'success');
      }
      
    });
  }
}
