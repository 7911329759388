import { BrowserModule } from '@angular/platform-browser';

import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './common/home/home.component';
// import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { LoginComponent } from './common/account/login/login.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BsDatepickerModule, DatepickerModule, BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ProfileComponent } from './common/profile/profile.component';
import { QuotesComponent } from './common/quotes/quotes.component';
import { DataTablesModule} from 'angular-datatables';
import { PrivacyPolicyComponent } from './common/privacy-policy/privacy-policy.component';
import { MotorInsuranceModule} from './motor-insurance/motor-insurance.module';
import { DashboardComponent } from './common/dashboard/dashboard.component';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MaterialModule } from './material.module';
import { MatInputModule, MatNativeDateModule } from '@angular/material';
import { LeftpanelComponent } from './common/dashboard/leftpanel/leftpanel.component';
import { CarInsuranceComponent } from './Car/car-insurance/car-insurance.component';
import { CarQuotationComponent } from './Car/car-quotation/car-quotation.component';
import { CarCoverageComponent } from './Car/car-coverage/car-coverage.component';
import { CarProposalComponent } from './Car/car-proposal/car-proposal.component';
import { ContactComponent } from './common/contact/contact.component';
import { AboutComponent } from './common/about/about.component';
import { ManagementTeamComponent } from './common/management-team/management-team.component';
import { CareersComponent } from './common/careers/careers.component';
import { DisclaimerComponent } from './common/disclaimer/disclaimer.component';
import { DndPolicyComponent } from './common/dnd-policy/dnd-policy.component';
import { SitemapComponent } from './common/sitemap/sitemap.component';
import { InsuranceNewsComponent } from './common/insurance-news/insurance-news.component';
import { ClaimFormComponent } from './common/claim-form/claim-form.component';
import { ProductWordingComponent } from './common/product-wording/product-wording.component';
import { HealthInsuranceComponent } from './Health/health-insurance/health-insurance.component';
import { FamilyHealthInsuranceComponent } from './Health/family-health-insurance/family-health-insurance.component';
import { CoronaKavachComponent } from './Health/corona-kavach/corona-kavach.component';
import { ArogyaSanjeevniComponent } from './Health/arogya-sanjeevni/arogya-sanjeevni.component';
import { SeniorCitizenComponent } from './Health/senior-citizen/senior-citizen.component';
import { CriticalIllnessComponent } from './Health/critical-illness/critical-illness.component';
import { SuperTopupComponent } from './Health/super-topup/super-topup.component';
import { PersonalAccidentComponent } from './Health/personal-accident/personal-accident.component';
import { BikeInsuranceComponent } from './TwoWheeler/bike-insurance/bike-insurance.component';
import { BikeCoverageComponent } from './TwoWheeler/bike-coverage/bike-coverage.component';
import { BikeQuotationComponent } from './TwoWheeler/bike-quotation/bike-quotation.component';
import { BikeProposalComponent } from './TwoWheeler/bike-proposal/bike-proposal.component';
import { BikeInsuranceRenewalComponent } from './RenewPolicy/bike-insurance-renewal/bike-insurance-renewal.component';
import { CarInsuranceRenewalComponent } from './RenewPolicy/car-insurance-renewal/car-insurance-renewal.component';
import { HealthInsuranceRenewalComponent } from './RenewPolicy/health-insurance-renewal/health-insurance-renewal.component';
import { TermLifeInsuranceComponent } from './LifeInsurance/term-life-insurance/term-life-insurance.component';
import { PrivateCarTwoWheelerComponent } from './Knowledge Center/private-car-two-wheeler/private-car-two-wheeler.component';
import { IndividualFamilyHealthPlanComponent } from './Knowledge Center/individual-family-health-plan/individual-family-health-plan.component';
import { IrdiaNewsComponent } from './Knowledge Center/irdia-news/irdia-news.component';
import { TermLifeComponent } from './Knowledge Center/term-life/term-life.component';
import { TravelComponent } from './Knowledge Center/travel/travel.component';
import { OtherProductComponent } from './Knowledge Center/other-product/other-product.component';
import { DomesticTravelInsuranceComponent } from './Travel Insurance/domestic-travel-insurance/domestic-travel-insurance.component';
import { InternationalTravelInsuranceComponent } from './Travel Insurance/international-travel-insurance/international-travel-insurance.component';
import { SeniorCitizenTravelInsuranceComponent } from './Travel Insurance/senior-citizen-travel-insurance/senior-citizen-travel-insurance.component';
import { StudentTravelInsuranceComponent } from './Travel Insurance/student-travel-insurance/student-travel-insurance.component';
import { CommercialVehicleInsuranceComponent } from './commercial-vehicle-insurance/commercial-vehicle-insurance.component';
import { RegistrationportalComponent } from './common/account/registrationportal/registrationportal.component';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { EncrDecrService } from './services/EncrDecrService';
import { CarRtoComponent } from './Car/car-rto/car-rto.component';
import { BikeRtoComponent } from './TwoWheeler/bike-rto/bike-rto.component';
import { HealthIndexComponent } from './Health/health-insurance/health-index/health-index.component';
import { BuildplanComponent } from './Health/health-insurance/buildplan/buildplan.component';
import { QuotationComponent } from './Health/health-insurance/quotation/quotation.component';
import { HealthProposalComponent } from './Health/health-insurance/health-proposal/health-proposal.component';
import { QuotationsComponent } from './Health/health-insurance/quotations/quotations.component';
import { PaymentsummeryComponent } from './Health/health-insurance/paymentsummery/paymentsummery.component';
import { HealthpaymentsuccessComponent } from './Health/health-insurance/healthpaymentsuccess/healthpaymentsuccess.component';
import { HealthpaymentfailedComponent } from './Health/health-insurance/healthpaymentfailed/healthpaymentfailed.component';
import { ViewplanComponent } from './Health/viewplan/viewplan.component';
import { ForgetpasswordComponent } from './common/account/forgetpassword/forgetpassword.component';
import { MaintenanceComponent } from './common/maintenance/maintenance.component';

import { RegisterPospComponent } from './POSP/register-posp/register-posp.component';
import { AddDetailsComponent } from './POSP/add-details/add-details.component';
import { AddPosDetailsComponent } from './POSP/add-pos-details/add-pos-details.component';
import { DashboardPOSPComponent } from './POSP/dashboard-posp/dashboard-posp.component';
import { AcceptedPosComponent } from './POSP/accepted-pos/accepted-pos.component';
import { RejectedPosComponent } from './POSP/rejected-pos/rejected-pos.component';
import { TotalPosComponent } from './POSP/total-pos/total-pos.component';
import { OnholdPospComponent } from './POSP/onhold-posp/onhold-posp.component';
import { PospPendingRequestComponent } from './POSP/posp-pending-request/posp-pending-request.component';
import { POSPheaderComponent } from './POSP/pospheader/pospheader.component';
import { POSPfooterComponent } from './POSP/pospfooter/pospfooter.component';
import { PospLandingPageComponent } from './POSP/posp-landing-page/posp-landing-page.component';
import { CustomerPageComponent } from './customer-page/customer-page.component';
import { BlogComponent } from './common/blog/blog.component';
import { JobOpeningComponent } from './common/job-opening/job-opening.component';
import { SingleViewBlogComponent } from './common/single-view-blog/single-view-blog.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { CompareHealthComponent } from './Health/health-insurance/compare-health/compare-health.component';
import { MyInspectionComponent } from './common/my-inspection/my-inspection.component';
import { MyQuotationComponent } from './my-quotation/my-quotation.component';
import { TermsOfUseComponent } from './common/terms-of-use/terms-of-use.component';
import { MyPolicyComponent } from './common/my-policy/my-policy.component';
import {MatStepperModule} from '@angular/material/stepper';


@NgModule({

  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ProfileComponent ,
    QuotesComponent,
    PrivacyPolicyComponent,
    DashboardComponent,
    LeftpanelComponent,
    CarInsuranceComponent,
    CarQuotationComponent,
    CarCoverageComponent,
    CarProposalComponent,
    ContactComponent,
    AboutComponent,
    ManagementTeamComponent,
    CareersComponent,
    DisclaimerComponent,
    DndPolicyComponent,
    SitemapComponent,
    InsuranceNewsComponent,
    ClaimFormComponent,
    ProductWordingComponent,
    HealthInsuranceComponent,
    FamilyHealthInsuranceComponent,
    CoronaKavachComponent,
    ArogyaSanjeevniComponent,
    SeniorCitizenComponent,
    CriticalIllnessComponent,
    SuperTopupComponent,
    PersonalAccidentComponent,
    BikeInsuranceComponent,
    BikeCoverageComponent,
    BikeQuotationComponent,
    BikeProposalComponent,
    CarRtoComponent,
    BikeRtoComponent,
    HealthIndexComponent,
    BuildplanComponent,
    QuotationComponent,
    HealthProposalComponent,
    QuotationsComponent,
    PaymentsummeryComponent,
    HealthpaymentsuccessComponent,
    HealthpaymentfailedComponent,
    BikeInsuranceRenewalComponent ,
    CarInsuranceRenewalComponent,
    HealthInsuranceRenewalComponent,
    TermLifeInsuranceComponent,
    PrivateCarTwoWheelerComponent,
    IndividualFamilyHealthPlanComponent,
    IrdiaNewsComponent,
    TermLifeComponent,
    TravelComponent,
    OtherProductComponent,
    DomesticTravelInsuranceComponent,
    InternationalTravelInsuranceComponent,
    SeniorCitizenTravelInsuranceComponent,
    StudentTravelInsuranceComponent,
    CommercialVehicleInsuranceComponent,
    RegistrationportalComponent,
    ViewplanComponent,
    ForgetpasswordComponent,
    MaintenanceComponent,
    RegisterPospComponent,
    AddDetailsComponent,
    AddPosDetailsComponent,
    DashboardPOSPComponent,
    AcceptedPosComponent,
    RejectedPosComponent,
    TotalPosComponent,
    OnholdPospComponent,
    PospPendingRequestComponent,
    POSPheaderComponent,
    POSPfooterComponent,
    PospLandingPageComponent,
    CustomerPageComponent,
    BlogComponent,
    JobOpeningComponent,
    SingleViewBlogComponent,
    CompareHealthComponent,
    MyInspectionComponent,
    MyQuotationComponent,
    TermsOfUseComponent,
    MyPolicyComponent

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgMultiSelectDropDownModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    BrowserAnimationsModule,
    DataTablesModule,MotorInsuranceModule,MaterialModule,
    MatNativeDateModule,
    MatDatepickerModule,AutocompleteLibModule,MatInputModule,
    NgIdleKeepaliveModule.forRoot(),
    MatStepperModule


  ],
  providers: [BsDatepickerConfig,MatDatepickerModule,EncrDecrService],
  bootstrap: [AppComponent]
})
export class AppModule { }
