import { Component, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  QuotationModel,
  premiumModel,
  addonMaster,
  addon,
  twoWheelerDetails,
} from "../../common/Models/twoWheeler";

import { Router, ActivatedRoute } from "@angular/router";
import { MotorService } from "src/app/services/motor.service";
import { MasterService } from "src/app/services/master.service";
import Swal from "sweetalert2";
import { voluntryExcess } from "src/app/common/Models/motor.master";

import {
  MomentDateModule,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material";
declare var $: any;
export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MMMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
};

@Component({
  selector: "app-bike-coverage",
  templateUrl: "./bike-coverage.component.html",
  styleUrls: ["./bike-coverage.component.css"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class BikeCoverageComponent implements OnInit {
  addonList: addon[] = [];
  isNcbDeclear: boolean = false;
  isPrevPolDtls: boolean = false;
  quoteModel: QuotationModel = new QuotationModel();
  voluntaryExcess: voluntryExcess[] = [];
  addonCollection: any[] = [];
  vehicleData: twoWheelerDetails;
  modalPoup: String = "divClose";
  dynAddons = [];
  minDate: Date;
  maxDate: Date;
  ischeckprevpolicy: boolean = true;
  isDeclare: boolean;
  isSAOD: boolean = true;
  expdate;
  PreviousInsurer: String;
  InsurerName: any;
  products: any[] = [];
  productsInsurerList: any[] = [];
  SelectCovers: boolean = true;
  SelectPreviousPolicydetails: boolean = false;
  SelectClaimdetails: boolean = false;

  constructor(
    private router: Router,
    private activeRouter: ActivatedRoute,
    private motorService: MotorService,
    private masterService: MasterService
  ) {
    const currentDate = new Date();

    this.minDate = new Date();
    this.minDate.setDate(currentDate.getDate() - 90);
    currentDate.setDate(currentDate.getDate() + 90);
    this.maxDate = currentDate;
    this.expdate = new FormControl(this.minDate);
  }

  ngOnInit(): void {
    debugger;
    $(".modal-backdrop").remove();
    this.getQuoteData();
    $(".carousel").carousel({
      interval: false,
    });
    sessionStorage.setItem("PrevPolDtls", "Yes");
    this.quoteModel.isPrevPolDtls = "Yes";
    $("#divPrevPolDtls").hide();
    this.quoteModel.PreviousPolicyType = "comprehensive";

    this.getInsurerName();
  }

  ngAfterViewChecked() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  getAddons() {
    debugger;
    this.masterService
      .getAddons(this.quoteModel.VehicleType)
      .subscribe((res: addon[]) => {
        this.addonList = res;
        if (this.addonList.length > 0) {
          this.addonList.forEach((v, i) => {
            this.dynAddons[v.AddOnId] = "No";
          });
        }
      });
  }
  
  checkUncheckAll() {
    debugger;
    if ($("#chkAddon")[0].checked) {
      this.addonList.forEach((v, i) => {
        this.addonCollection.push(v.AddOnId);
        this.dynAddons[v.AddOnId] = "Yes";
      });
    } else {
      this.addonCollection = [];
      this.addonList.forEach((v, i) => {
        this.dynAddons[v.AddOnId] = "No";
      });
    }
  }

  getVoluntaryExcess() {
    this.masterService
      .getVoluntryExcess(this.quoteModel.VehicleType)
      .subscribe((res: voluntryExcess[]) => {
        this.voluntaryExcess = res;
      });
  }
  addCollection(value, addonId) {
    let idx = -1;

    let found = this.addonList.find((element) => element.AddOnId == addonId);
    if (this.addonCollection.length > 0)
      idx = this.addonCollection.indexOf(addonId);
    if (idx < 0 && value == "Yes") {
      this.addonCollection.push(addonId);
      if (this.dynAddons[addonId] == value) this.dynAddons[addonId] = "";
      else this.dynAddons[addonId] = "Yes";
    } else {
      this.addonCollection.splice(idx, 1);
      if (this.dynAddons[addonId] == value) this.dynAddons[addonId] = "";
      else this.dynAddons[addonId] = "No";
    }
  }
  goPolicyType(policyType) {
    this.quoteModel.PolicyType = policyType;
    this.quoteModel.PreviousPolicyType = policyType;
    // if(policyType=="ThirdParty" || policyType=="Comprehensive"){
    //   this.quoteModel.PaidDriver="Yes";
    if (this.quoteModel.PolicyType == "StandaloneOD") {
      this.quoteModel.PACoverOwner = "No";
    } else {
      this.quoteModel.PACoverOwner = "Yes";
    }

    //   this.quoteModel.RestrictTPPDLiability="Yes";
    //   this.quoteModel.PACover="Yes";
    // }else{
    this.quoteModel.PaidDriver = "No";
    // this.quoteModel.PACoverOwner="No";
    this.quoteModel.RestrictTPPDLiability = "No";
    this.quoteModel.AutomobileMembership = "No";
    this.quoteModel.PACover = "No";

    // }
    if (policyType == "ThirdParty") {
      this.quoteModel.ClaimMade = "No";
      this.quoteModel.CurrentNCB = "0";
      this.isNcbDeclear = true;
    }
  }

  getQuoteData() {
    let id = this.activeRouter.snapshot.queryParamMap.get("sessionId");
    this.motorService
      .getQuotationQuery("", id, 3)
      .subscribe((result: QuotationModel) => {
        this.quoteModel = result;
        if (this.quoteModel.CurrentPolicyType != "NEW")
          this.quoteModel.CurrentPolicyType = "";

        this.quoteModel.CurrentNCB = "";
        this.quoteModel.ClaimMade = "";
        this.quoteModel.PreviousPolicyExpirDate = "";
        this.expdate = new FormControl(this.quoteModel.PreviousPolicyExpirDate);
        this.quoteModel.LastNCB = "";
        this.goPolicyType(this.quoteModel.PolicyType);
        this.getAddons();
        this.getVoluntaryExcess();
        this.quoteModel.UserId = localStorage.getItem("UserId");
        debugger;
        let regdate = new Date(
          Number(this.quoteModel.YearOfPurchase),
          Number(this.quoteModel.MonthOfPurchase) - 1,
          Number(this.quoteModel.DayOfPurchase)
        );
        var ageDifMs = Date.now() - regdate.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        let age = Math.abs(ageDate.getUTCFullYear() - 1969);

        // IsSaod true logic

        if (
          this.quoteModel.CurrentPolicyType.toString().toUpperCase() != "NEW"
        ) {
          function createDate(day, month, year) {
            return new Date(year, month - 1, day);
          }

          let dayOfPurchase = this.quoteModel.DayOfPurchase;
          let monthOfPurchase = this.quoteModel.MonthOfPurchase;
          let yearOfPurchase = this.quoteModel.YearOfPurchase;

          let newRegistrationDate = createDate(
            dayOfPurchase,
            monthOfPurchase,
            yearOfPurchase
          );
          let currentDate = new Date();

          function getDateFourYearsAndTenMonthsAgo(date) {
            let result = new Date(date);
            result.setFullYear(result.getFullYear() - 4);
            result.setMonth(result.getMonth() - 10);
            return result;
          }

          let dateFourYearsAndTenMonthsAgo =
            getDateFourYearsAndTenMonthsAgo(currentDate);

          function isWithinFourYearsAndTenMonths(date, referenceDate) {
            return (
              date >= dateFourYearsAndTenMonthsAgo && date <= referenceDate
            );
          }

          let isSAOD = isWithinFourYearsAndTenMonths(
            newRegistrationDate,
            currentDate
          );

          this.isSAOD = isSAOD;
          this.isSAOD = isSAOD;

          if (this.isSAOD) {
            this.quoteModel.PolicyType = "StandaloneOD";
          }

          sessionStorage.setItem("CurrentSaOD", this.isSAOD.toString());

          // console.log("isSAOD:", this.isSAOD);

          // IsSao true logic End
        }
      });
  }

  applyChanges() {
    this.SelectCovers = false;
    this.SelectPreviousPolicydetails = true;
    let validate = true;
    if (this.quoteModel.PACover == "Yes") {
      if (
        this.quoteModel.SumInsured == "" ||
        this.quoteModel.SumInsured == null
      ) {
        Swal.fire("select suminsured", "", "error");
        validate = false;
      }
    } else {
      this.quoteModel.SumInsured = "";
      validate = true;
    }
    let addons = [];
    if (this.addonCollection.length > 0) {
      for (let i = 0; i < this.addonCollection.length; i++) {
        addons.push({ AddonId: this.addonCollection[i] });
      }
      this.quoteModel.Addons = addons;
      // this.premiumList=[];
    } else {
      this.quoteModel.Addons = null;
    }
    sessionStorage.setItem("addons", JSON.stringify(addons));
    if (validate == true) {
      if (this.quoteModel.CurrentPolicyType != "NEW") $("#myModal").show();
      else {
        this.saveQuote();
      }
    }
  }
  goClaim() {
    debugger;
    if (
      this.quoteModel.CurrentPolicyType == "RollOver" &&
      this.quoteModel.isPrevPolDtls == "Yes"
    ) {
      if (
        this.quoteModel.PreviousPolicyExpirDate == "" ||
        this.quoteModel.PreviousPolicyExpirDate == null
      ) {
        Swal.fire("Select previous policy expiry date", "", "error");
        return;
      }
    }
    this.quoteModel.isPrevPolDtls = $(
      'input[name="PrevPolDtls"]:checked'
    ).val();
    //quoteModel.PreviousPolicyEndDate
    if (this.quoteModel.CurrentPolicyType != "") {
      if (this.quoteModel.CurrentPolicyType == "Expired") {
        this.saveQuote();
      } else if (
        this.quoteModel.CurrentPolicyType != "Expired" &&
        this.quoteModel.isPrevPolDtls == "No"
      ) {
        this.saveQuote();
      } else {
        $("#divExpDate").removeClass("active");
        $("#divClaim").addClass("active");
        this.SelectPreviousPolicydetails = false;
        this.SelectClaimdetails = true;
        this.modalPoup = "goClaim";
      }
      if (this.quoteModel.PolicyType == "ThirdParty") {
        this.saveQuote();
      } else {
        $("#divClaim").addClass("active");
        this.SelectClaimdetails = true;
      }
    } else {
      Swal.fire("Select Your Current Policy.", "", "error");
    }
  }

  // claimMade(claim, value) {
  //   if (claim == "Yes") {
  //     this.quoteModel.ClaimMade = "Yes";
  //     this.quoteModel.LastNCB = "";
  //     this.quoteModel.CurrentNCB = "0";
  //   } else {
  //     this.quoteModel.ClaimMade = "No";
  //     this.quoteModel.LastNCB = value.split("-")[0];
  //     this.quoteModel.CurrentNCB = value.split("-")[1];
  //   }
  // }

  getValue(event: Event): string {
    const target = event.target as HTMLInputElement;
    return target.value;
  }

  claimMade(claim: string, value: string) {
    debugger;

    if (claim === 'Yes') {
      this.quoteModel.ClaimMade = 'Yes';
      this.quoteModel.LastNCB = '';
      this.quoteModel.CurrentNCB = '0';
    } else {
      this.quoteModel.ClaimMade = 'No';
      const values = value.split('-');
      this.quoteModel.LastNCB = values[0] || ''; // Fallback to empty string if undefined
      this.quoteModel.CurrentNCB = values[1] || '0'; // Fallback to "0" if undefined
    }
  }

  Declare(event) {
    if (event.target.checked) {
      this.isDeclare = true;
    } else {
      this.isDeclare = false;
    }
  }

  saveQuote() {
    debugger;
    let validate = true;
    if (this.quoteModel.isPrevPolDtls == "Yes") {
      // this.quoteModel.PreviousPolicyExpirDate="";
      //this.saveQuote();
      if (!this.ischeckprevpolicy) {
        $("#divClaim").addClass("active");
        $("#divExpDate").removeClass("active");
        this.SelectPreviousPolicydetails = false;
        this.SelectClaimdetails = true;
        $("#PreviousPolicyEndDate").css("display", "none");
        $("#SelectPreviousCompanyName").css("display", "none");
        // if(this.quoteModel.ClaimMade==''){return false}
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() - 90);
        this.minDate = currentDate;

        this.maxDate = new Date();
      }
      this.isPrevPolDtls = true;
    }

    //if (this.quoteModel.UserId == null) {
    // Swal.fire('Please login', '', 'error');
    //validate = false;
    //}
    //if (this.quoteModel.UserId == "") {
    //Swal.fire('Please login', '', 'error');
    //validate = false;
    //}
    if (this.quoteModel.AutomobileMembership == "Yes") {
      if (
        this.quoteModel.MembershipNo == "" ||
        this.quoteModel.MembershipNo == null
      ) {
        Swal.fire("enter membership no", "", "error");
        validate = false;
      }
      if (
        this.quoteModel.MembershipExpiry == "" ||
        this.quoteModel.MembershipExpiry == null
      ) {
        Swal.fire("enter membership expiry date", "", "error");
        validate = false;
      }
    }
    if (this.quoteModel.PACover == "Yes") {
      this.quoteModel.NoOfPassengers = this.quoteModel.SeatingCapacity;
      if (
        this.quoteModel.SumInsured == "" ||
        this.quoteModel.SumInsured == null
      ) {
        Swal.fire("select suminsured", "", "error");
        validate = false;
      }
    }
    if (
      this.quoteModel.PolicyType != "ThirdParty" &&
      this.quoteModel.CurrentPolicyType != "NEW" &&
      this.quoteModel.CurrentPolicyType != "Expired"
    ) {
      if (
        this.quoteModel.ClaimMade == "" ||
        this.quoteModel.ClaimMade == null
      ) {
        Swal.fire("Select claim in your policy?", "", "error");
        validate = false;
      }
    }
    if (
      this.quoteModel.CurrentPolicyType != "Expired" &&
      this.quoteModel.ClaimMade == "No"
    ) {
      if (
        this.quoteModel.CurrentNCB == "" ||
        this.quoteModel.CurrentNCB == null
      ) {
        Swal.fire("Select NCB", "", "error");
        validate = false;
      }
    }
    // if (this.isNcbDeclear == true) {

    //   if (this.quoteModel.CurrentNCB == "" || this.quoteModel.CurrentNCB == null) {
    //     Swal.fire('Select NCB', '', 'error');
    //     validate = false;
    //   }
    // }
    if (this.quoteModel.CurrentNCB != "") {
      if (this.isNcbDeclear == false) {
        Swal.fire(
          "Please declare that all the furnished details are correct",
          "",
          "error"
        );
        validate = false;
      }
    }

    if (validate == true) {
      let addons = [];
      if (this.addonCollection.length > 0) {
        for (let i = 0; i < this.addonCollection.length; i++) {
          addons.push({ AddonId: this.addonCollection[i] });
        }
        this.quoteModel.Addons = addons;
        // this.premiumList=[];
      } else {
        this.quoteModel.Addons = null;
      }
      if (this.quoteModel.ClaimMade == "" && !this.ischeckprevpolicy) {
        return false;
      }
      if (this.quoteModel.ClaimMade == "Yes") this.quoteModel.CurrentNCB = "";
      this.motorService
        .postQuoteDataAndAddons(this.quoteModel)
        .subscribe((result: any) => {
          if (result && result.status == "done")
            sessionStorage.setItem("sessionId", result.Id);

          this.router.navigate(["/bike-quotation"], {
            queryParams: { sessionId: result.Id },
          });
          // this.router.navigateByUrl('/two-wheeler-quotation?sessionId='+result.Id);
        });
    }
  }

  calculateDate($event, type) {
    if ($event == null) return;

    if (type == "MembershipExpiryDate") {
      this.quoteModel.MembershipExpiry = $event.value._d;
    } else {
      let d = $event.target.value._i.date;
      let mnth = $event.target.value._i.month + 1;
      let year = $event.target.value._i.year;
      let dtExp = $event.target.value;
      debugger;
      let dd = d.toString().length == 1 ? "0" + d : d.toString();
      let mm = mnth.toString().length == 1 ? "0" + mnth : mnth.toString();
      this.quoteModel.PreviousPolicyExpirDate = year + "-" + mm + "-" + dd;

      if (this.quoteModel.CurrentPolicyType == "Expired") {
        var ageDifMs = Date.now() - dtExp.getTime();
        if (ageDifMs < 0) {
          Swal.fire("Previous Policy End Date", "", "error");
          return;
        }
      }
      //this.goClaim();
    }
    debugger;
    var dtToday = new Date();
    var dtexp = new Date(this.quoteModel.PreviousPolicyExpirDate);
    dtexp.setFullYear(
      $event.target.value._i.year,
      $event.target.value._i.month,
      $event.target.value._i.date
    );

    if (dtexp < dtToday) {
      this.quoteModel.CurrentPolicyType = "Expired";
    }

    if (dtexp < dtToday || this.quoteModel.CurrentPolicyType == "Expired") {
      this.isPrevPolDtls = true;
      sessionStorage.setItem("PrevPolDtls", "Yes");
      this.quoteModel.isPrevPolDtls = "Yes";
    } else {
      this.isPrevPolDtls = false;
      // sessionStorage.setItem("PrevPolDtls","No");
    }
    $("#divPrevPolDtls").show();
  }
  goDivRegistrationYear(type) {
    //alert(type);
    if (type == "divClose") {
      $("#myModal").hide();
    } else {
      if (type != "back") {
        this.modalPoup = "divClose";
        $("#divClaim").removeClass("active");
        $("#divExpDate").addClass("active");
        this.SelectPreviousPolicydetails = false;
        this.SelectClaimdetails = true;
      }  else {
        if (this.quoteModel.CurrentPolicyType == 'NEW') {
          this.router.navigateByUrl('/bike-rto?type=NEW');
        } else {
          this.router.navigateByUrl('/bike-rto?type=ROLLOVER');
        }
      }
    }
  }
  checkPreviousPolicyEndDate(type) {
    //alert(type);

    this.modalPoup = "goClaim";
    if (type == "Yes") {
      this.quoteModel.CurrentPolicyType = "Expired";
      sessionStorage.setItem("PrevPolDtls", "Yes");
      this.quoteModel.isPrevPolDtls = "Yes";
      //this.saveQuote();
      //$('#divClaim').addClass('active');
      //$('#divExpDate').removeClass('active');
      $("#divPrevPolDtls").css("display", "block");
      // $('#PreviousPolicyEndDate').css("display", "block");
      this.quoteModel.PreviousPolicyExpirDate = "";
      sessionStorage.setItem("PrevPolDtls", "No");
      this.quoteModel.isPrevPolDtls = "No";
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 90);
      const strDate = new Date();
      strDate.setFullYear(strDate.getFullYear() - 90);
      this.minDate = strDate;

      this.maxDate = currentDate;
      this.isPrevPolDtls = true;
      this.quoteModel.ClaimMade = "No";
    } else {
      this.ischeckprevpolicy = false;

      this.quoteModel.CurrentPolicyType = "RollOver";
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() - 90);
      this.minDate = currentDate;
      const endDate = new Date();
      endDate.setDate(currentDate.getDate() + 90);
      this.maxDate = endDate;
      this.isPrevPolDtls = false;
      this.quoteModel.PreviousPolicyExpirDate = "";
      $("#divPrevPolDtls").css("display", "block");
      // $('#PreviousPolicyEndDate').show();
      // $("#PreviousPolicyEndDate").css("display", "block");
      $("#PreviousPolicyEndDate1").css("display", "none");
    }
  }

  // setPreviousInsurer(selectedValue: string) {
  //   debugger;
  //   // this.quoteModel.ExField3 = selectedValue;
  //   sessionStorage.setItem("PreInsurer", selectedValue);
  // }

  setPreviousInsurer(event: Event) {
    const target = event.target as HTMLInputElement; // Cast target to HTMLInputElement
    const selectedValue = target.value; // Safely access 'value' property

    debugger; // For debugging purposes
    // Store the selected value in session storage
    sessionStorage.setItem('PreInsurer', selectedValue);

    // If you need to update another model property, uncomment the line below
    // this.quoteModel.ExField3 = selectedValue;
  }

  getInsurerName() {
    debugger;
    this.motorService.getPreviousInsurerDetails().subscribe((res: any[]) => {
      if (res) {
        this.products = res;
      }
    });
  }

  setPrevPolDtlsSession(val) {
    debugger;
    sessionStorage.setItem("PrevPolDtls", val);
    this.quoteModel.isPrevPolDtls = val;

    if (this.quoteModel.CurrentPolicyType == "Expired") {
      if (val == "Yes") {
        this.isPrevPolDtls = true;
        $("#PreviousPolicyEndDate").css("display", "block");
        $("#SelectPreviousCompanyName").css("display", "block");
      } else {
        this.quoteModel.ClaimMade = "No";
        this.quoteModel.CurrentNCB = "0";
        this.isNcbDeclear = true;
        this.isPrevPolDtls = false;
        this.quoteModel.CurrentPolicyType = "Expired";
        $("#PreviousPolicyEndDate").css("display", "none");
        $("#SelectPreviousCompanyName").css("display", "none");
      }
      // this.saveQuote();
    } else {
      if (val == "Yes") {
        this.isPrevPolDtls = true;
        $("#PreviousPolicyEndDate").css("display", "block");
        $("#SelectPreviousCompanyName").css("display", "block");
      } else {
        this.quoteModel.ClaimMade = "No";
        this.quoteModel.CurrentNCB = "0";
        this.isNcbDeclear = true;
        this.isPrevPolDtls = false;
        this.quoteModel.CurrentPolicyType = "Expired";
        $("#PreviousPolicyEndDate").css("display", "none");
        $("#SelectPreviousCompanyName").css("display", "none");
        // this.saveQuote();
      }
    }
  }

  Back(){
    this.SelectCovers = true;
    this.SelectPreviousPolicydetails = false;
    this.SelectClaimdetails = false;
  }
}
