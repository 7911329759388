import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute  } from '@angular/router';
import { MotorService } from 'src/app/services/motor.service';
import  {QuotationModel,premiumModel,twoWheelerDetails}  from '../../common/Models/twoWheeler';
import Swal from 'sweetalert2';
import { trim } from 'jquery';
declare var $: any;
@Component({
  selector: 'app-paymentsuccess',
  templateUrl: './paymentsuccess.component.html',
  styleUrls: ['./paymentsuccess.component.css']
})
export class PaymentsuccessComponent implements OnInit {
  urlCopy:string="";
  isEmail: boolean = false;
  isSMS: boolean = false;
  Email:string="";
  Mobile:string="";
  quoteModel:QuotationModel=null;
  constructor(private router:ActivatedRoute,private proposalService:MotorService) { }
  policyNo;
  amount;
  policyUrl;
  transactionId;
  image;
  transactionDate;fullName;planType;proposalid;DownloadLink;
  companyId;KYCStatus; 
  KYCDownloadFlag:boolean=false;

  ngOnInit() {
    this.policyNo=this.router.snapshot.queryParamMap.get('proposalNo');
    this.amount=this.router.snapshot.queryParamMap.get('amount');
    this.policyUrl=this.router.snapshot.queryParamMap.get('policyUrl');
    this.proposalid=this.router.snapshot.queryParamMap.get('proposalid');
    localStorage.removeItem('CoverType')
    localStorage.removeItem('quotaionId')
    localStorage.removeItem('members')
    localStorage.removeItem('customer')

     this.proposalService.GetPaymentDetails(this.proposalid).subscribe((res:any)=>{        
        this.transactionId=res.TransactionId;
        this.amount=res.Premium;
        this.image=res.CompanyLogo;
        this.transactionDate=res.TransactionDate;
        this.fullName=res.FullName; 
        this.planType=res.PlanName;
        this.DownloadLink=res.DownloadLink;

        this.companyId=res.CompanyId;
        
        if(this.companyId=='33')
        {
          this.KYCStatus=this.router.snapshot.queryParamMap.get('KYCStatus');
          if(this.KYCStatus!='DONE'){
            this.KYCDownloadFlag=true;
          }
        }

      });
      //PlanName
  }

  copyUrl(type){
    if(type=='Email'){
      this.urlCopy = '';
      this.isEmail=true;
    }
    if (type == 'SMS') {
      this.urlCopy = '';
      this.isSMS=true;
    }
    if(type=='Link'){debugger;
      this.urlCopy = this.DownloadLink; 
      // this.urlCopy = this.urlCopy +"&proposalid=" + this.proposalId;   
      var $temp = $("<input>");
      var $url = this.DownloadLink;
      $("body").append($temp);
      $temp.val($url).select();
      document.execCommand("copy");
      $temp.remove();
      //$("p").text("URL copied!");  
      Swal.fire('URL copied!', '', 'success');
      
      $("#shareQuote").modal("hide");
    }
    if(type=='copy'){
      this.urlCopy = this.DownloadLink;
    }
  }
  
  shareQuote(){  
    // let textEmail=this.textEmail.nativeElement.value;
    let textEmail = this.Email;
      let textMobile = this.Mobile;
      if (this.isEmail == true) {
    if(textEmail==""){
      Swal.fire('Plz. enter Email Id', '', 'error');
      return;
    }else{
      this.quoteModel.ExField1=textEmail;
      this.quoteModel.ExField2=this.DownloadLink;
      // this.quoteModel.UserId=this.proposalForm.controls['Email'].value;
      // this.quoteModel.IMT23=this.proposalForm.controls['MobileNo'].value;
      this.proposalService.QuotesMailer([this.quoteModel],null).subscribe((result:any)=>{
        if(result>0){
          $("#shareQuote").modal("hide");
          Swal.fire('Private Car Policy details successfully sent.', '', 'success');
        }
        else{
          Swal.fire('Private Car Policy details are not successfully sent.', '', 'error');
        }
                  
      });
    }
    }
    if (this.isSMS == true) {debugger;
      if (textMobile == "") {
        Swal.fire('Please enter Mobile number', '', 'error');
        return;
      } else {
        debugger;
        // this.quoteModel.ExField1 = textMobile;
        // this.quoteModel.ExField2 = window.location.href;
        // this.quoteModel.UserId=this.proposalForm.controls['Email'].value;
        // this.quoteModel.IMT23=this.proposalForm.controls['MobileNo'].value;
        this.proposalService.QuotesSMS(textMobile, this.DownloadLink,this.fullName,3).subscribe((result: any) => {
          if (result > 0) {
            $("#shareQuote").modal("hide");
            Swal.fire('Private Car Policy details successfully sent.', '', 'success');
          }
          else {
            Swal.fire('Private Car Policy details successfully sent.', '', 'error');
          }
  
        });
      }
    }
  }
}
