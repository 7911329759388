import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettings } from 'src/app/globals';

@Component({
  selector: 'app-leftpanel',
  templateUrl: './leftpanel.component.html',
  styleUrls: ['./leftpanel.component.css']
})
export class LeftpanelComponent implements OnInit {

  UserId:number;
  dropdownContent:boolean=false;
  IsAdmin:any;
  AdminUrl:any;
  code: string;
  constructor(private routerActive:ActivatedRoute) { 
    if(sessionStorage.getItem('UserId')!=null){     
      this.UserId=parseInt(sessionStorage.getItem('UserId'));
    }
  }
  showDropdown(){
    if(this.dropdownContent==true){
      this.dropdownContent=false;
    }
    else{
      this.dropdownContent=true;
    }
  }
  ngOnInit() {debugger
    if(AppSettings.DomainUrl=='https://www.arkavach.com:8089' || AppSettings.DomainUrl=='http://localhost:11274'){
      if (this.UserId == 2 || this.UserId == 3 || this.UserId == 1499 || this.UserId == 1993 || this.UserId == 1613 
        || this.UserId == 2296 || this.UserId == 1950 || this.UserId == 2572 || this.UserId == 2649) {
        this.IsAdmin = true;
      }
      else{
        this.IsAdmin = false;
      }
      // this.AdminUrl='http://125.99.37.103:3614/';
      this.AdminUrl='https://pre-podarkavach.com:3614/';
    }
    else{
      if (this.UserId == 2 || this.UserId == 3 || this.UserId == 81 || this.UserId == 83 || this.UserId == 86 || this.UserId == 88 
        || this.UserId == 2296 ) {
        this.IsAdmin = true;
      }
      else{
        this.IsAdmin = false;
      }
      this.AdminUrl='http://1.22.180.122:4139/';
    }
    
    this.code = this.routerActive.snapshot.queryParamMap.get('code');
  }


}
